import SearchIcon from "@mui/icons-material/Search";
import { FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import useShortcut from "./useShortcut";
import { useRef } from "react";

interface SearchFieldProps {
  value: string
  placeholder?: string
  shortcutKeys?: string[]
  fullWidth?: boolean
  height?: string
  size: 'small' | 'medium'
  padding?: string
  clearable?: boolean
  onChange: (value: string) => void
}

export default function SearchField(props: SearchFieldProps) {
  const inputRef = useRef<HTMLInputElement>()

  useShortcut({
    keys: props.shortcutKeys ?? null,
    onPress: () => {
      inputRef.current?.focus()
    },
  })

  return (
    <FormControl  variant="outlined" fullWidth={props.fullWidth} >
      <TextField
        variant={"outlined"}
        value={props.value}
        size={props.size}
        placeholder={`${props.placeholder}`}
        onChange={(event) => props.onChange(event.target.value)}
        InputProps={{
          style: {
            height: props.height ? props.height : "",
            padding: props.padding ? props.padding : "",
            width: '100%', // Set the width to 100% to ensure the whole text is displayed in one line.
          },

          endAdornment: (
            <InputAdornment position="start">
              <IconButton type="button" aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
