import { QueryClient, useQuery } from "@tanstack/react-query";
import { Persister, PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

//export function useQuery, useQueryClient

export enum AsyncQueryStatus {
	loading = 0,
	error = 1,
	success = 2,
}

export interface AsyncStoreResult<T> {
	status: AsyncQueryStatus;
	data: T;
	error: object | unknown;
	isFetching: boolean;
	refetch: () => Promise<T>;
	isPending: boolean
}

export interface AsyncStore {
	/**
	 * Wraps the application with the relevant jsx elements, enabling store usage
	 * @param wrapped
	 */
	bootStrap(wrapped: JSX.Element): JSX.Element;

	/**
	 * Invokes the relevant functional call in the store and returns the relevant function for usage in a functional component
	 * @param key the query/store key
	 * @param fn the query function which should return the relevant model expected
	 */
	addQuery<T>(key: string, fn: () => Promise<T>): AsyncStoreResult<T>;
	invalidateQuery<T>(key: string): void;
}

export class QueryAsync implements AsyncStore {
	queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 1000 * 60 * 60 * 24, // 24 hours
			},
		},
	});

	persisted: Persister = createSyncStoragePersister({
		storage: window.localStorage,
	});


	addQuery<T>(key: string, fn: () => Promise<T>): AsyncStoreResult<T> {
		const keys = [key];
		const { refetch, ...result } = useQuery<Promise<T>, object, T>({
			queryKey: keys,
			queryFn: fn,
		});

		return {
			...result,
			refetch: () => refetch(),
			status: AsyncQueryStatus[result.status],
		};
	}

	invalidateQuery<T>(key: string) {
   return this.queryClient.invalidateQueries({ queryKey: [key] })
	}



	bootStrap(wrapped: JSX.Element): JSX.Element {
		const opts = { persister: this.persisted };
		return (
			<PersistQueryClientProvider client={this.queryClient} persistOptions={opts}>
				{wrapped}
			</PersistQueryClientProvider>
		);
	}
}

export function defaultStore(): AsyncStore {
	return new QueryAsync();
}
