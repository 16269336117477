import Button from "@mui/material/Button";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";

interface CustomMenuItemProps {
  icon?: React.ReactNode;
  text: string;
  onClick?: () => void;
  classes?: any;
}

// const styles = createStyles({
//   menuItem: {
//     fontSize: 12,
//     alignItems: "center",
//     fontWeight: 500,
//     color: "#8f93b3",
//     justifyContent: "flex-end",
//     paddingRight: "0px",
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
//   contentWrapper: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     width: "100%",
//   },
//   icon: {
//     color: "#8f93b3",
//     minWidth: "30px"
//   },
//   text: {
//     textTransform: "none",
//     color: "#8f93b3",
//     textAlign: "right",
//   },
// })

const CustomMenuItem = (props: CustomMenuItemProps) => {
  const { icon, text, onClick, classes } = props;

  return (
    <Button key={text} onClick={onClick}>
      <MenuItem className={classes?.menuItem}>
        { icon && <div className={classes?.contentWrapper}>
          <ListItemIcon className={classes?.icon}>{icon}</ListItemIcon>
        </div>}
        <Typography variant="inherit" className={classes?.text}>{text}</Typography>
      </MenuItem>
    </Button>
  );
};

export default CustomMenuItem
