import { Button, Grid, SxProps } from "@mui/material";
import { ReactElement, useState } from "react";
import  AddIcon  from "@mui/icons-material/Add";
import SearchField from "./SearchField";
import { ActionsCell } from "./ActionsCell";
import { ExportListDropDow } from "./ExportListDropDown";
import { BundleListNameCell } from "./BundleListNameCell";

interface BeforeBundlesTableProps {
  sx?: SxProps
  title: string
  children?: ReactElement[]
  placeholder?: string
  buttonOnClick?: () => void
  bundle: any
}

const BeforeBundlesTable = (props: BeforeBundlesTableProps) => {
  const {title,placeholder,buttonOnClick,bundle} = props
  const [filter, setFilter] = useState<string>('')
  const {bundleListName,lastUpdate} = bundle
  return (
    <Grid
      container
      style={{ display: "absolute" }}
    >
        <Grid  container  direction={"row"} justifyContent={"space-between"}  alignItems={"flex-end"}  style={{padding:"10px"}}>
          <Grid item xs={4} xl={6}>
            {bundleListName.name}
          </Grid>
        <Grid item >
          <SearchField
            size="small"
            padding={"0px"}
            height={"36px"}
            placeholder={`${placeholder}`}
            shortcutKeys={['/']}
            value={filter}
            onChange={setFilter}
          />
        </Grid>
        <Grid item >
          <ExportListDropDow/>
        </Grid>
        <Grid item >
          <Button onClick={buttonOnClick} variant={"contained"} color={"primary"}>
            <AddIcon/>
            {`Add Bundle IDs`}
          </Button>
        </Grid>
        <Grid item>
          <ActionsCell row={bundle} actionsNames={["Duplicate Bundle List","Clear Bundle List"]} callbackActions={ [() => {}, () => {}]}/>
        </Grid>
      </Grid>
      <Grid item style={{padding:"10px"}}>
        <BundleListNameCell name={""} createdBy={bundleListName.createdBy} id={bundleListName.id} date={lastUpdate}/>
      </Grid>
    </Grid>
  )
}


export default BeforeBundlesTable
