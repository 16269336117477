import {
  FormControl,
  Switch,
  Typography,
  IconButton, TextField, Button, CardHeader, Card, CardContent
} from "@mui/material";
import { Controller } from 'react-hook-form';
import Divider from "@mui/material/Divider";
import { DemandItem, Network, StrategyType } from "./deal";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import DemandSearchAutocomplete from "./demandSearchAutoComplete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Box from "@mui/material/Box";
import React from 'react';


interface AddDemandProps {
  control: any; // Replace with the correct type from react-hook-form
  setValue: Function
  getValues: Function
  selectedGeoSearchItems?: any
  strategyGeoValue?: StrategyType
  selectedDemands: Network[]
}

export default function AddDemand( props:AddDemandProps) {
  const { control, setValue,selectedDemands  } = props
  const [errorMessage, setErrorMessage] = useState('');
  const [demandAutocompleteKey, setDemandAutocompleteKey] = useState(0);

  const handleAddDemand = (item: DemandItem) => {
    // Construct a Network object from the selected DemandItem
    const newNetwork: Network = {
      "demand-id": item["demand-id"],
      "demand-name": item["demand-name"],
      "demand-display-name": item["demand-display-name"],
      "active": true, // default to true
      "seats": [] // default to empty array
    };

    // Check if the newNetwork is already in selectedDemands
    if (!selectedDemands.some(demand => demand["demand-id"] === newNetwork["demand-id"])) {
      // Add the new Network object to the existing array if not a duplicate
      const updatedNetworks = [...selectedDemands, newNetwork];
      setValue("deal.demand.networks", updatedNetworks);
      setDemandAutocompleteKey(0)
    } else {
      setDemandAutocompleteKey(prev => prev +1)
      // Optionally handle the duplicate case (e.g., show a message to the user)
    }
  };


  const handleDemandDelete = (index: number) => {
    // Remove the Network object at the given index
    const updatedNetworks = selectedDemands.filter((_, i) => i !== index);
    setValue("deal.demand.networks", updatedNetworks);
  };

  const toggleActive = (index: number, isActive: boolean) => {
    // Create a new array with the updated 'active' state for the selected demand
    const updatedNetworks = selectedDemands.map((network, i) => {
      if (i === index) {
        return { ...network, active: isActive };
      }
      return network;
    });

    // Update the form state
    setValue("deal.demand.networks", updatedNetworks);
  };




  const [openDialog, setOpenDialog] = useState(false);
  const [currentSeatIndex, setCurrentSeatIndex] = useState<number | null>(null);
  const [newSeat, setNewSeat] = useState('');

  const handleOpenDialog = (index: number) => {
    setCurrentSeatIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewSeat('');
    setErrorMessage('');
  };

  const renderAddDemand = ({ index, style }: ListChildComponentProps) => {
    const network = selectedDemands[index];

    // Generate the display for the seats with delete icons
    const seatsDisplay = network.seats?.map((seat, seatIndex) => (
      <React.Fragment key={`seat-${seatIndex}`}>
        <Typography variant="body2" component="span" sx={{ display: 'inline', ml: 1, mr: 1 }}>
          {seat}
        </Typography>
        <IconButton size="small" onClick={() => handleDeleteSeat(index, seatIndex)} sx={{ padding: '0px', marginRight: network.seats && seatIndex < network.seats.length - 1 ? '5px' : '0' }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        {/* Render comma if not the last seat */}
        {network.seats && seatIndex < network.seats.length - 1 && (
          <Typography variant="body2" component="span" sx={{ display: 'inline' }}>
            {", "}
          </Typography>
        )}
      </React.Fragment>
    ));


    return (
      <Box style={style} key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Controller
            name={`deal.demand.networks[${index}].active`}
            control={control}
            render={({ field }) => (
              <Switch
                edge="start"
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  toggleActive(index, e.target.checked);
                }}
              />
            )}
          />
          <ListItemText primary={`${network["demand-display-name"]} (${network["demand-id"]})`} />
          {/* Parentheses are added only if there are seats */}
          {network.seats && network.seats.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {'('}
              {seatsDisplay}
              {')'}
            </Box>
          )}
        </Box>
        <Box>
          <IconButton onClick={() => handleOpenDialog(index)} sx={{ mr: 1 }}>
            <AddBoxIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleDemandDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const handleAddSeat = () => {
    if (currentSeatIndex !== null && newSeat) {
      // Copy the current state of selectedDemands to avoid direct state mutation
      const updatedDemands = [...selectedDemands];

      // Add the new seat to the specific network's seats
      const currentNetworkSeats = updatedDemands[currentSeatIndex].seats || [];
      if (currentNetworkSeats.includes(newSeat)) {
        setErrorMessage('This seat already exists.');
        return;
      }
      updatedDemands[currentSeatIndex].seats = [...currentNetworkSeats, newSeat];

      // Update the form state with the new demands array
      setValue("deal.demand.networks", updatedDemands, { shouldValidate: true });

      // Reset newSeat state and close the dialog
      setNewSeat('');
      handleCloseDialog();
    }
  };

  const handleDeleteSeat = (networkIndex: number, seatIndex:number) => {
    // Create a new array without the deleted seat
    const currentSeats = selectedDemands[networkIndex].seats || [];

    // Create a new array without the deleted seat
    const updatedSeats = [...currentSeats];
    updatedSeats.splice(seatIndex, 1); // Remove the seat

    // Update the selected demands with the new seats array
    const updatedDemands = selectedDemands.map((network, i) => {
      if (i === networkIndex) {
        return { ...network, seats: updatedSeats };
      }
      return network;
    });

    // Update the form state with the new demands array
    setValue("deal.demand.networks", updatedDemands);
  };

  return (
    <Card variant={"outlined"} style={{marginTop:12}}>
      <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Participating Demand"} />
      <CardContent>
      <Controller
        name="deal.demand.networks"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <DemandSearchAutocomplete
              key = {demandAutocompleteKey}
              selectedOption={field.value}
              onOptionSelect={(item) => item && handleAddDemand(item)}
            />
          </FormControl>
        )}
      />
      <FixedSizeList
        height={300}
        itemSize={46}
        style={{ border: '1px solid black' }}
        itemCount={selectedDemands.length}
        overscanCount={5}
        itemData={selectedDemands}
      >
        {renderAddDemand}
      </FixedSizeList>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add a Seat</DialogTitle>
        <DialogContent>
          <Controller
            name={currentSeatIndex !== null ? `deal.demand.networks[${currentSeatIndex}].seats` : ""}
            control={control}
            render={() => (
              <TextField
                autoFocus
                margin="dense"
                label="Seat"
                type="text"
                fullWidth
                variant="outlined"
                value={newSeat}
                onChange={(e) => setNewSeat(e.target.value)} // Just update newSeat state, not the form state
                error={!!errorMessage}
                helperText={errorMessage}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddSeat}>Add</Button>
        </DialogActions>
      </Dialog>

      </CardContent>
    </Card>
  )
}
