import { Grid, Typography } from "@mui/material";
import { BundleListIdCopy } from "./BundleListIdCopy";

export interface BundleListNameCellProps {
  name: string
  createdBy: string
  id: number
  date?: string
}

export function BundleListNameCell(props: BundleListNameCellProps) {
  const { name, createdBy, id,date } = props;
  const direction = date !== undefined ? "row" : "column"
  const dateText =  date !== undefined ? `on ${date}` : ""
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid  container direction={direction} alignItems={date !== undefined ? "center" : undefined}>
        <Grid  item spacing={1} alignItems="center">
          <Typography variant="inherit">
            {`${name} Created By ${createdBy} ${dateText}`}
          </Typography>
        </Grid>
        <Grid item>
          <BundleListIdCopy id={id} />
        </Grid>
      </Grid>
    </Grid>
  );
}
