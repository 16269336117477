import Table, { DEFAULT_PAGE_SIZE } from "../../bundleListTable/Table";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import TableHeader from "../../shared/TableHeader";
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";
import { AsyncQueryStatus, AsyncStore ,AsyncStoreResult} from "@dtx/ui-core/src/query";
import { Pad } from "../padForm/pad";
import { useNavigate } from "react-router-dom";
import { TrackingPixels } from "../../dealsForm/deal";

interface Props {
  filter?: string;
  store: any;
}

interface DataInterface {
   data: Pad[];
  'result-count': number;
  'total-count': number;
  'page-number': number;
  'total-pages': number;
}

export default function PadListTable(props: Props) {
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate()
  const createNewPad = () => {
    navigate('/pads/edit')
  }

  const key = `padList-${rowsPerPage}-${pageNumber}`;

  const result: AsyncStoreResult<DataInterface | null> = props.store.addQuery(key, async () => {
    try {
      const response = await fetch(`/api/pads/getAllPads?numOfItems=${rowsPerPage}&page=${pageNumber}`, {
        method: 'get',
        mode: 'cors',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching pad list:", error);
      throw error;
    }
  });

  useEffect(() => {
    result.refetch();
  }, [rowsPerPage, pageNumber, refreshKey]);

  const handlePageChange = (pageIndex: number) => {
    setPageNumber(pageIndex + 1);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  const editPad = async (id: number) => {
    let padToEdit = result.data!.data.find((element) => element.pad_id === id)
    const padId = padToEdit.pad_id
    const setupCriteria = padToEdit["setup_criteria"]
    try {
      if (setupCriteria == "seatId") {
        const response = await fetch(`/api/pads/getSeatsByPadId?PadID=${padId}`, {
          method: 'get',
          mode: 'cors',
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const setupCriteriaList = await response.json();

        padToEdit.setup_criteria_list = setupCriteriaList.map(item => item.seat_id)
      }
    } catch (error) {
        console.error("Error fetching pad list:", error);
        throw error;
      }

    if (padToEdit) {
      navigate(`/pads/edit/${id}`, { state: { pad: padToEdit, refreshKey: key}})
    } else {
      console.error(`Pad with id ${id} not found`)
    }
  }

  const padData = result.data ?? { data: [], 'result-count': 0, 'total-count': 0, 'page-number': 1, 'total-pages': 1 };

  const statusStyles = {
    active: {
      backgroundColor: '#3FFF0020',
    },
    inactive: {
      backgroundColor: '#fd5c6320',
    },
    default: {
      backgroundColor: 'transparent',
    },
  };

  const columns = [
    { id: 'padId', accessor: 'padId', header: 'PAD ID', cell: ({ row }) => row.original.pad_id },
    { id: 'padRuleName', accessor: 'padRuleName', header: 'Pad Rule Name', cell: ({ row }) => row.original.pad_name },
    { id: 'marketplaceType', accessor: 'marketplaceType', header: 'Marketplace Type', cell: ({ row }) => row.original.marketplace_type },
    { id: 'dspName', accessor: 'dspName', header: 'DSP', cell: ({ row }) => row.original.dsp_name },
    { id: 'setupCriteria', accessor: 'setupCriteria', header: 'Setup Criteria', cell: ({ row }) => row.original.setup_criteria },
    { id: 'discount', accessor: 'discount', header: 'Discount', cell: ({ row }) => `${row.original.discount}%` },
    { id: 'status', accessor: 'active', header: 'Status', cell: ({ row }) => (row.original.active ? 'Active' : 'Inactive') },
    { id: 'createdBy', accessor: 'createdBy', header: 'Created By', cell: ({ row }) => row.original.created_by },
    { id: 'audit', accessor: 'audit', header: 'Audit', cell: ({ row }) => row.original.audit },
    { id: 'actions', display: 'actions', size: 65, cell: ({ row }) => <EditIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => {
        editPad(row.original['pad_id'])
      }} /> },
  ];


  return (
    <Grid container>
      <Grid item style={{ paddingTop: "70px", width: "100%" }}>
        <Paper>
          <TableHeader title={"PAD Lists"} placeholder={"New Rule"} onAddButtonClicked={() => createNewPad()} />
          <Divider />
          {result.status === AsyncQueryStatus.loading || result.isFetching || result.isPending ? (
            <div id="load">
              <div>G</div>
              <div>N</div>
              <div>I</div>
              <div>D</div>
              <div>A</div>
              <div>O</div>
              <div>L</div>
            </div>
          ) :  result.status === AsyncQueryStatus.error || result.status === undefined ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
              There was an error. Please try again.
            </div>
          ) : (
            <div style={{ overflowX: 'auto' }}>
          <Table
            id="pad-list-table"
            data={padData.data ?? []}
            columns={columns}
            totalCount={padData['total-count']}
            totalPages={padData['total-pages']}
            currentPage={padData['page-number']}
            rowPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            manualPagination={true}
            onRowsPerPageChange={handleRowsPerPageChange}
            getRowProps={(row) => {
              const padList = row.original;
              const rowStyle = statusStyles[padList.active ? 'active' : 'inactive'] || statusStyles.default;
              return {
                style: { cursor: 'pointer', ...rowStyle },
              };
            }}
          />
            </div>)}
        </Paper>
      </Grid>
    </Grid>
  );
}