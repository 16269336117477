//created using https://transform.tools/json-to-typescript

export interface Pad {
  pad_id: string
  "pad_name": string,
  "marketplace_type": MarketplaceType,
  "dsp": number
  "setup_criteria": SetupCriteria
  "discount": number
  active: boolean
  "created_at": Date
  "created_by": string,
  "setup_criteria_list"?: string[]
}

export enum SetupCriteria {
  seatId = "seatId"
}

export enum MarketplaceType {
  PMP = "PMP",
  OX = "OX"
}

export enum DSP {
  ttd = "TTD",
  dv360 = "DV360"
}





