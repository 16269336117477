import { useEffect, useState, Fragment } from "react";
import { Autocomplete, CircularProgress, Paper, TextField } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";

interface DemandAutocompleteProps {
  onOptionSelect: (item: DemandItem | null) => void;
  selectedOption?: DemandItem | null;
}

export interface DemandItem {
  "demand-display-name": string
  "demand-id": number
  "demand-name": string
  "active"?: boolean
  "seats"?:[]
}


export default function DemandSearchAutocomplete(props: DemandAutocompleteProps) {
  const {onOptionSelect, selectedOption} = props
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly DemandItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const debouncedSearchValue = useDebounce(inputValue, 500)

  const getNoOptionsText = () => {
    if (loading) return "Loading...";
    if (!inputValue.trim()) return "Start typing to view results";
    if (dataFetched && options.length === 0) return `No results for "${inputValue}"`;
    return null;
  };


  type PaperProps = React.ComponentProps<typeof Paper>;

  const CustomPaper: React.FC<PaperProps> = (props) => (
    <Paper {...props} style={{ ...props.style, maxHeight: 200, overflow: 'auto' }} />
  );

  type DivProps = React.HTMLProps<HTMLDivElement>;

  const CustomListboxComponent: React.FC<DivProps> = (props) => (
    <div {...props} style={{ ...props.style, maxHeight: 200, overflow: 'auto' }}>
      {props.children}
    </div>
  );

  const fetchData = async (searchTerm: string) => {
    try {
      const response = await fetch(`/api/deals-aux/search-demands?q=${encodeURIComponent(searchTerm)}`, {
        method: "GET",
        mode: "cors"
      });

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const json = await response.json();

      return Array.isArray(json) ? json : [];
    } catch (error) {
      console.error("An error occurred while fetching data", error);
      return [];
    }
  }

  useEffect(() => {
    let active = true;

    if (debouncedSearchValue === "") {
      setOptions([])
      setDataFetched(false);
      setLoading(false);
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const results = await fetchData(debouncedSearchValue);

        if (active) {
          setOptions([...results]);
          setDataFetched(true);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demand"
      sx={{ width: 300 }}
      open={open}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedOption ?? null} // Ensure controlled component with nullish coalescing
      onChange={(_, newValue) => {
        onOptionSelect(newValue);
      }}
      isOptionEqualToValue={(option, value) => option["demand-display-name"] === value["demand-display-name"]}
      getOptionLabel={(option) => {
        if (option && "demand-display-name" in option && "demand-id" in option) {
          return `${option["demand-display-name"]} (${option["demand-id"]})`;
        }
        return '';
      }}
      PaperComponent={CustomPaper}
      ListboxComponent={CustomListboxComponent}
      options={options}
      loading={loading}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);

        if (newInputValue.trim() === "") {
          setDataFetched(false);
          setOptions([]);
        }
      }}
      filterOptions={(x) => x}
      noOptionsText={getNoOptionsText()}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter a demand name"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
