import { ReactNode } from "react";
import { Grid ,Container} from "@mui/material";
import SideMenu from "../sideMenu/SideMenu";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


export interface DealsLayoutProps {
  children?: ReactNode;
  menuItems: string[];
}

const DealsLayout = (props: DealsLayoutProps) => {
  const { children, menuItems } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const pathNames = location.pathname.split('/').filter((x) => x);

  const handleBreadcrumbClick = (index: number) => {
    const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
    navigate(routeTo); // Use navigate to change the route.
  };

  return (
    <Grid container >
      <Grid item xs={12} sm={0} >
        <SideMenu menuItems={menuItems} handleBreadcrumbClick={handleBreadcrumbClick} pathNames={pathNames} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Container maxWidth={false} style={{ overflowX: 'hidden' }}>
          {children}
        </Container>
      </Grid>
    </Grid>
  )
}
export default DealsLayout;
