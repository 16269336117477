import { useState } from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useParams } from "react-router-dom";
import BeforeBundlesTable from "./BeforeBundlestable";
import BundlesTableAppBar from "./bundlesTableAppBar";

interface Props {
  filter?: string
  store: any

}

export default function BundlesTable(props: Props) {
  const [filter, setFilter] = useState("")
  const [numOfSelectedRows,setNumOfSelectedRows] = useState(0)
  const [selectedRows,setSelectedRows]: any[] = useState([])

  const bundleListId = useParams().id
  const bundles = props.store.addQuery("bundleList", async () => {
    const response = await fetch("/bundleList");
    const data = await response.json();
    return data
  })
    const data =  bundles.data ? bundles.data.filter((bundleList) => bundleList.bundleListName.id.toString() === bundleListId)[0].bundles : []

  const handleRowClick = (bundleList: any) => {
    const toAddSelectedRows = !selectedRows.includes(bundleList)
    setSelectedRows((prevSelectedRows) => {
      if (toAddSelectedRows) {
        return [...prevSelectedRows, bundleList]
      } else {
        return prevSelectedRows.filter((row) => row !== bundleList)
      }
    })

    setNumOfSelectedRows((prevNumOfSelectedRows) => prevNumOfSelectedRows + (toAddSelectedRows ? 1 : -1))
  }

  const bundleList = bundles.data ? bundles.data.filter((bundleList) => bundleList.bundleListName.id.toString() === bundleListId)[0] : null


  return (
    <Grid container >
      <Grid item   style={{paddingTop: "70px",paddingLeft: "20px", width: "90%"}} >
        <Grid item>
          {"Bundle List Details"}
        </Grid>
        <Paper>
        <BeforeBundlesTable title={"Bundle List Details"} placeholder={"Search in this Bundle list"}  buttonOnClick={() => console.log("button add bundle list")}  bundle={bundleList}/>
        <BundlesTableAppBar numOfSelected={numOfSelectedRows}/>
        <Table>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index} onClick={() => handleRowClick(item)}  >
                <TableCell>{item}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}


