import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { AppContract } from "./AppContract";
import { theme } from "./styled/Theme";
import { defaultStore } from "./query";
import { ThemeProvider } from "@mui/material/styles";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarNotificationsProvider } from "./context/SnackbarNotificationsProvider";
import SnackbarNotifications from "./context/SnackbarNotifications";

const store = defaultStore();

export default function(app: AppContract): void {
  app.onAsyncStore(store);
  const AppElement = app.createApplicationComponent();
  ReactDOM.createRoot(document.getElementById("root") ?? document.createElement("root")).render(
    store.bootStrap(
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <SnackbarNotificationsProvider>
              <SnackbarNotifications />
              <CssBaseline />

              <React.Fragment>
                <AppElement />
              </React.Fragment>

            </SnackbarNotificationsProvider>
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    )
  );
}
