import { FormControl, InputLabel, SxProps } from "@mui/material";
import { ReactElement, useState } from "react";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchField from "../bundleListTable/SearchField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface BeforeTableProps {
  sx?: SxProps
  title: string
  children?: ReactElement[]
  placeholder?: string
  onAddButtonClicked?: () => void
  statuses?: string[];
  selectedStatus?: string;
  onStatusChange?: (status: string) => void;
  onTextFilterChanged?: (filter: string) => void;
  onFilterChange?: (filter: string) => void;
  showSearchField?: boolean
  showFilterByStatus?: boolean

}

const TableHeader = (props: BeforeTableProps) => {
  const { title, placeholder, onAddButtonClicked, statuses, selectedStatus, onStatusChange, onTextFilterChanged, showSearchField, showFilterByStatus } = props;
  const [filter, setFilter] = useState<string>('');

  // Function to handle filter changes and call the onChange callback
  const handleFilterChange = (value: string) => {
    setFilter(value);

    // Call the onChange callback with the updated filter
    onTextFilterChanged?.(value);
  };

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      style={{ padding: "12px" }}
    >
      {showSearchField && (
        <Grid item xs={10} style={{ paddingRight: "10px" }}>
          <SearchField
            size="small"
            clearable={true}
            placeholder={`Search ${placeholder}`}
            value={filter}
            onChange={handleFilterChange}
          />
        </Grid>
      )}
      {showSearchField || showFilterByStatus ? null : <Grid item xs />}
      <Grid item container xs={2} justifyContent="flex-end" alignItems="center">
        {showFilterByStatus && (
          <FormControl style={{ marginRight: "10px" }}>
            <InputLabel id="filter-by-status">Filter by status</InputLabel>
            <Select
              size={"small"}
              labelId={"filter-by-status"}
              label={"Filter by status"}
              value={selectedStatus || ""}
              onChange={(event) => onStatusChange?.(event.target.value as string)} // Call onStatusChange if it's defined
              style={{ width: "150px" }}
            >
              {statuses?.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button
          onClick={onAddButtonClicked}
          variant={"contained"}
          color={"primary"}
        >
          <AddIcon />
          {`Add ${placeholder}`}
        </Button>
      </Grid>
    </Grid>


  );
};

export default TableHeader;
