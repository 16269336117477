import MuiTablePagination from "@mui/material/TablePagination";
import { useCallback } from "react";
import { Table as TableType } from "@tanstack/react-table";
import { DEFAULT_PAGE_SIZE } from "./Table";

export interface TablePaginationProps {
  manualPagination: boolean;
  disabled?: boolean;
  pageIndex?: number;
  pageSize?: number;
  pageSizeOptions?: number[];
  totalCount?: number;
  onPageSizeChange?: (pageSize: number) => void;
  table: TableType;
  colSpan: number;
  onPageChange?: (pageIndex: number) => void;
  onRowsPerPageChange?: (pageSize: number) => void;
}

export function TablePagination(props: TablePaginationProps) {
  const {
    table,
    manualPagination,
    totalCount,
    pageSizeOptions,
    colSpan,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  const count = manualPagination ? totalCount : table.getFilteredRowModel().rows.length;

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const handleRowsPerPageChange = useCallback(
    debounce((newPageSize: number) => {
      table.setPageSize(newPageSize);
      if (onRowsPerPageChange) {
        onRowsPerPageChange(newPageSize);
      }
    }, 500),
    [table, onRowsPerPageChange]
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      table.setPageIndex(newPage);
      if (onPageChange) {
        onPageChange(newPage);
      }
    },
    [table, onPageChange]
  );

  const page = props.pageIndex !== undefined ? props.pageIndex - 1 : 0;
  return (
    <MuiTablePagination
      count={count}
      page={page}
      rowsPerPage={props.pageSize ?? DEFAULT_PAGE_SIZE}
      rowsPerPageOptions={pageSizeOptions}
      colSpan={colSpan}
      onPageChange={(_, page) => handlePageChange(page)}
      onRowsPerPageChange={(event) => handleRowsPerPageChange(Number(event.target.value))}
    />
  );
}
