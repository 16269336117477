import { Grid, PaginationProps, SxProps } from "@mui/material";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableFooter from "@mui/material/TableFooter";
import MuiTableHead from "@mui/material/TableHead";

import MuiTableRow, { TableRowProps as MuiTableRowProps } from "@mui/material/TableRow";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
  OnChangeFn
} from "@tanstack/react-table";
import SkeletonRow from "./SkeletonRow";
import { BundleListTableHeader } from "./TableHeader";
import { TablePagination } from "./TablePagination";

// Defaults
const DEBOUNCE_DURATION = 200 // ms
const DEFAULT_PROP_GETTER = () => ({})
const SKELETON_ROWS = 10
export const DEFAULT_PAGE_SIZE = 10

const DEFAULT_PAGINATION_PROPS = {
  manualPagination: false,
  disabled: false,
  pageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: [10, 25, 50, 100],
} as PaginationProps

const DEFAULT_SORTING_PROPS = {
  manualSorting: false,
} as SortingProps

export interface TableProps<TData> {
  id?: string
  data: TData[]
  columns: ColumnDef<TData, any>[]
  loading?: boolean
  globalFilter?: string
  globalFilterFn?: FilterFn<TData>
  paginationProps?: PaginationProps
  sortingProps?: SortingProps
  getRowProps?: (row: Row<TData>) => MuiTableRowProps
  getSubRows?: (row: TData) => TData[] | undefined
  'data-testid'?: string
  sx?: SxProps
  paginationState?: PaginationState;
  onPageChange?: (pageIndex: number) => void;
  totalCount?: number
  currentPage?: number
  rowPerPage?: number
  totalPages?: number
  manualPagination?: boolean
  onRowsPerPageChange?: (pageSize: number) => void;
}

type SortingProps =
  | {
  manualSorting?: false
}
  | {
  manualSorting: true
  sorting: SortingState
  onSortingChange: OnChangeFn<SortingState>
}

export default function Table<TData>(props: TableProps<TData>) {
  const totalCount = props?.totalCount ?? 0;
  const totalPages = props?.totalPages ?? 0;
  const manualPagination = props?.manualPagination
  const onPageChanged = props?.onPageChange
  const paginationProps = DEFAULT_PAGINATION_PROPS

  const debouncedGlobalFilter = DEBOUNCE_DURATION

  const sortingProps = props.sortingProps ?? DEFAULT_SORTING_PROPS

  const getRowProps = props.getRowProps ?? ((row) => {
    return {
      style: { cursor: 'pointer' },
      onClick: () => {
        // go directly to an existing path to avoid redirects from next config,
        // otherwise authentication will fail as it relies on a token param in
        // URL when rendered fully
      },
    }
  })

  // Table instance
  const table = useReactTable<TData>({
    data: props.data,
    columns: props.columns,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    getSubRows: (row) => props.getSubRows?.(row),
    getExpandedRowModel: getExpandedRowModel(),
    paginateExpandedRows: false,

    // even if undefined, passing the prop will disable default global filtering
    ...(props.globalFilterFn ? { globalFilterFn: props.globalFilterFn } : {}),

    state: {
      globalFilter: debouncedGlobalFilter,
      ...(paginationProps.manualPagination
        ? {
          pagination: {
            pageIndex: props.currentPage ??  paginationProps.pageIndex,
            pageSize: props.rowPerPage ?? paginationProps.pageSize,
          },
        }
        : {}),
      ...(sortingProps.manualSorting
        ? {
          sorting: sortingProps.sorting,
        }
        : {}),
    },

    manualPagination: paginationProps.manualPagination,
    ...(paginationProps.manualPagination
      ? {
        onPaginationChange: props.onPageChange ?? paginationProps.onPageChange,
      }
      : {}),
    getPaginationRowModel: paginationProps.manualPagination
      ? undefined
      : getPaginationRowModel(),

    manualSorting: sortingProps.manualSorting,
    ...(sortingProps.manualSorting
      ? { onSortingChange: sortingProps.onSortingChange }
      : {}),
    getSortedRowModel: sortingProps.manualSorting
      ? undefined
      : getSortedRowModel(),
  })

  // Helpers
  const hasNoRecords = table.getRowModel().rows.length === 0 && !props.loading
  const colSpan = table.getVisibleFlatColumns().length

  return (
    <Grid container direction="column">
    <Grid item>
    <MuiTable
      id={props.id}
      style={{ tableLayout: 'fixed' }}
      data-testid={props['data-testid']}
      sx={props.sx}
    >
      <MuiTableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <MuiTableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <BundleListTableHeader key={header.id} header={header} />
            ))}
          </MuiTableRow>
        ))}
      </MuiTableHead>
      <MuiTableBody>
        {props.loading ? (
          <SkeletonRows colSpan={colSpan} />
        ) : (
          Object.values(table.getRowModel().rowsById).map((row) => (
            <MuiTableRow key={row.id} {...getRowProps(row)}>
              {row.getVisibleCells().map((cell) => (
                <MuiTableCell key={cell.id} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          ))
        )}
        {hasNoRecords ? <NoRecordsRow colSpan={colSpan} /> : null}
      </MuiTableBody>

      {!paginationProps.disabled && (
        <MuiTableFooter>
          <MuiTableRow>
            <TablePagination
              table={table}
              colSpan={colSpan}
              // manual pagination need to pass as true because of api call
              manualPagination={manualPagination? manualPagination : false}
              disabled={false}
              pageSize={props.rowPerPage}
              pageIndex={props.currentPage}
              totalCount = {totalCount}
              onPageChange= {props.onPageChange}
              onRowsPerPageChange={props.onRowsPerPageChange}
            />
          </MuiTableRow>
        </MuiTableFooter>
      )}
    </MuiTable>
    </Grid>
  </Grid>
  )
}


function NoRecordsRow({ colSpan }: { colSpan: number }) {
  return (
    <MuiTableRow>
      <MuiTableCell
        sx={{ textAlign: 'center', px: 3, py: 2 }}
        colSpan={colSpan}
      >
        No records to display
      </MuiTableCell>
    </MuiTableRow>
  )
}

function SkeletonRows({ colSpan }: { colSpan: number }) {
  const rows = new Array(SKELETON_ROWS).fill(true)
  return (
    <>
      {rows.map((_, index) => (
        <SkeletonRow key={`skeleton-row--${index}`} cells={colSpan} />
      ))}
    </>
  )
}
