import { MockedPath } from "@dtx/ui-core/src/mock/browser";
import dayjs from 'dayjs'


const mockBundlesList = [
	{bundleListName: {name: "bundleList1", createdBy: "shahar kimhi", id: "143434"} ,
		lastUpdate: dayjs().format('DD/MM/YY'),
		bundles: ["com.blabla","58279047803456780t34"],
	},
	{
		bundleListName: {name: 'bundleList2', createdBy: "shahar kimhi", id: "143435"} ,
		lastUpdate: dayjs().format('DD/MM/YY'),
		bundles: ["58279047803456780t34","com.blabla"],
	},
];

const mockdealsList = [
	{status: "Activated" ,
		dealId: "DTX123456232" ,
		dealName: "Deal one" ,
		description: "Test deal1" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Normal Deal" ,
		createdBy: "Dory" ,
	},
	{status: "Stopped" ,
		dealId: "DTX123434" ,
		dealName: "Effys Deal" ,
		description: "Test deal2" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Special Deal" ,
		createdBy: "Effy" ,
	},
	{status: "Pending" ,
		dealId: "DTX123455" ,
		dealName: "Effys Deal 3" ,
		description: "Test deal3" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Special Deal" ,
		createdBy: "Effy" ,
	},
	{status: "Expired" ,
		dealId: "DTX1237" ,
		dealName: "Effys Deal 4" ,
		description: "Test deal4" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Regular Deal" ,
		createdBy: "Effy" ,
	},
];

const mockpadsList = [
	{padId: 1 ,
		padRuleName: "Effy PAD" ,
		marketplaceType: "OX" ,
		dspName: "TTD" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 1,
		status: "active" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 2 ,
		padRuleName: "Asafs PAD" ,
		marketplaceType: "PMP" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 5,
		status: "inactive" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 3 ,
		padRuleName: "Mors PAD" ,
		marketplaceType: "PMP" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 3,
		status: "active" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 4 ,
		padRuleName: "Test PAD" ,
		marketplaceType: "PMP" ,
		dspName: "Liftoff" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 7,
		status: "active" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 5 ,
		padRuleName: "Nikolays PAD" ,
		marketplaceType: "PMP" ,
		dspName: "Moloco" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 3,
		status: "inactive" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 6 ,
		padRuleName: "Sagis PAD" ,
		marketplaceType: "OX" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 3,
		status: "active" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 7 ,
		padRuleName: "Nikhils PAD" ,
		marketplaceType: "PMP" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 3,
		status: "active" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 8 ,
		padRuleName: "Tomers PAD" ,
		marketplaceType: "PMP" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 3,
		status: "inactive" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 9 ,
		padRuleName: "Shahars PAD" ,
		marketplaceType: "PMP" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 0,
		status: "inactive" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
	{padId: 10 ,
		padRuleName: "Teds PAD" ,
		marketplaceType: "PMP" ,
		dspName: "DV360" ,
		dsp: 600,
		setupCriteria: "SeatID" ,
		discount: 0,
		status: "active" ,
		createdAt: dayjs().format('DD/MM/YY'),
		createdBy: "effy",
	},
];



const bundleList = new MockedPath("/bundleList", JSON.stringify(mockBundlesList));

const dealList = new MockedPath("/dealList", JSON.stringify(mockdealsList));

const padList = new MockedPath("/padList", JSON.stringify(mockpadsList));

export const mockedPaths = (): Array<MockedPath> => {
	return [bundleList,dealList, padList];
};
