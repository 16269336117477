import { flexRender, Header } from "@tanstack/react-table";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableSortLabel from "@mui/material/TableSortLabel";


export interface BundleListTableHeaderProps {
  header: Header<TData, unknown>
}

export function BundleListTableHeader(props: BundleListTableHeaderProps) {
  const {header} = props
  const column = header.column
  const canSort = column.getCanSort()
  const sortDirection = column.getIsSorted() || undefined

  return (
    <MuiTableCell width={header.getSize()}>
      {header.isPlaceholder ? null : (
        <MuiTableSortLabel
          disabled={!canSort}
          active={Boolean(sortDirection)}
          direction={sortDirection}
          onClick={column.getToggleSortingHandler()}
        >
          {flexRender(column.columnDef.header, header.getContext())}
        </MuiTableSortLabel>
      )}
    </MuiTableCell>
  )
}
