import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemSecondaryAction, Paper,
  Select, Stack,
  Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useState, useEffect, Fragment } from "react";
import { Controller } from "react-hook-form";
import ListItemButton from "@mui/material/ListItemButton";
import EditableRow from './editableRow'
import CountrySearchAutocomplete from "../dealsForm/countrySearchAutoComplete";

interface dialogBulkProps<T> {
  title: string
  open: boolean
  setOpen: any
  fetchData: (stringArray: string[], queryType?: string, country?: string) => any
  optionsSelect?: string[]
  selectTitle?: string
  handleSave: (item?: T, isGeo?:boolean) => void
  selectedData: any[]
  control: any
  displayName?: string
}

export default function BulkDialog<T>(props: dialogBulkProps<T>) {
  const { title, optionsSelect,displayName, handleSave, selectTitle, fetchData, control,open, setOpen, selectedData} = props
  const [selectedOption, setSelectedOption] = useState("");
  const [existingResults, setExistingResults] = useState<T>([]);
  const [noneExistingResults, setNoneExistingResults] = useState([]);
  const [country, setCountry] = useState("");

  const handleClose = (value) => {
    setExistingResults([])
    setNoneExistingResults([])
    setOpen(false)
  }


  const parseBulkAddValues = (values) => {
    let parsedValues = []
    if (values.length) {
      let rows = values.split('\n')
      rows.forEach((row) => {
        let values = row.split(',')
        values.forEach((value) => {
          const trimmedValue = value.trim()
          const trimmedValueLower = trimmedValue.toLowerCase()
          if (trimmedValue.length && !parsedValues.some((existingValue) => existingValue.toLowerCase() === trimmedValueLower)) {
            parsedValues.push(trimmedValue)
          }
        })
      })
    }

    return parsedValues
  }

  const getItemRow = (item: string, index: number) => {
    return (
      <ListItem key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={item} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete"
                        onClick={() =>
                          setExistingResults(prevResults =>
                            prevResults.filter((item, i) => i !== index)
                          )
                        }   >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    )
  }


    const onEditValues = async (value, index) => {
      const parsedValues = parseBulkAddValues(value)
      const deleteEditedResult = noneExistingResults.filter((item, j) => j !== index)

      try {
        let responseData;
        if (optionsSelect && optionsSelect.length > 0) {
          if(country && country.trim() !== ""){
            responseData = await fetchData(parsedValues, selectedOption, country);
          }else{
            responseData = await fetchData(parsedValues, selectedOption);
          }

        } else {
          responseData = await fetchData(parsedValues);
        }
        if (responseData) {
          const notInIntersection = getNoneExists(
            Array.isArray(responseData)
              ? responseData
              : Object.entries(responseData).flatMap(([key, value]) => {
                if (value['result-type'] === 'postal_code') {
                  const postalCode = value['postal-code']; // assuming the key for postal code is 'postal-code'
                  return postalCode ? [key, postalCode.toLowerCase()] : [key];
                } else {
                  return value['sub-1-iso']
                    ? [key, value['sub-1-iso'].toLowerCase()]
                    : [key];
                }
              }),
            parsedValues
          );
          setNoneExistingResults([...deleteEditedResult,...notInIntersection])
          const valuesObj = Object.values(responseData).filter((value) => {
            const jsonString = JSON.stringify(value)
            return !existingResults.some(existingValue => JSON.stringify(existingValue) === jsonString)
          })
          setExistingResults([...existingResults,...valuesObj])
        } else {
          console.error("Invalid response structure")
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    const onSavesValues = (values) => {
    if(displayName === "display-name"){
      values.map((value) => handleSave(value, true))
    }else{
      values.map((value) => handleSave(value))
    }
      setExistingResults([])
      setNoneExistingResults([])
      setOpen(false)
    }

  const onSelectValues = async (value) => {
    let parsedValues = parseBulkAddValues(value)
    try {
      let responseData
      if (optionsSelect && optionsSelect.length > 0) {
        if(country && country.trim() !== ""){
          responseData = await fetchData(parsedValues, selectedOption, country);
        }else{
          responseData = await fetchData(parsedValues, selectedOption);
        }
      } else {
        responseData = await fetchData(parsedValues);
      }
      parsedValues = displayName === "language-code" ? parsedValues.filter((value) => Object.values(responseData["language-code"]) === value )  : parsedValues

      if (responseData)
      {
        const isArray = Array.isArray(responseData)
        const notInIntersection = getNoneExists(
          Array.isArray(responseData)
            ? responseData
            : Object.entries(responseData).flatMap(([key, value]) => {
              if (value['result-type'] === 'postal_code') {
                const postalCode = value['postal-code']; // assuming the key for postal code is 'postal-code'
                return postalCode ? [key, postalCode.toLowerCase()] : [key];
              } else {
                return value['sub-1-iso']
                  ? [key, value['sub-1-iso'].toLowerCase()]
                  : [key];
              }
            }),
          parsedValues
        );
setNoneExistingResults(notInIntersection)
        const valuesObj = Object.values(responseData)
        const filteredData = valuesObj.filter((value) => Object.keys(value).length !== 0)

        setExistingResults(filteredData)

      } else {
        console.error("Invalid response structure")
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }


  const getNoneExists = (existsValues : string[], allValues: string[]) => {
    const normalizedExistsValues = existsValues.map(value => value.toLowerCase());
    const newValues =allValues.filter(value => !normalizedExistsValues.includes(value.toLowerCase()));
    return newValues
  }

  useEffect(() => {
      setCountry("")
    }, [selectedOption]

  )

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Bulk {title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" color={"black"}>Copy and Paste</Typography>
            <Typography variant="h8" color={"black"}>Copy and paste a list of values from a spreadsheet or text file</Typography>
          </DialogContentText>

          {optionsSelect && (
            <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <FormControl sx={{ minWidth: 130 }}>
                  <InputLabel htmlFor="select-bulk">Choose one...</InputLabel>
                  <Select
                    autoFocus
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    label="Select Country"
                    inputProps={{
                      name: "select-bulk",
                      id: "select-bulk"
                    }}
                  >
                    {optionsSelect.map((item) => (
                      <MenuItem value={item} key={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              {(selectedOption === "Zip Code" || selectedOption === "City") && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <CountrySearchAutocomplete
                      selectedOption={country}
                      onOptionSelect={(selectedCountry) => {
                        setCountry(selectedCountry);
                      }}
                    />
                </Stack>
              )}
            </Stack>
          )}
        </DialogContent>
          <div>
            {existingResults.length > 0 || noneExistingResults.length > 0 ? (
              <Controller
              name="deal.conditions-json.language.value"
              control={control}
              render={({ field, fieldState, formState }) => (
                    <React.Fragment>
                      <DialogContent>
                      <Typography variant="h6" color={"black"}>
                        Verify {title}{" "}
                      </Typography>
                      <Typography variant="h8" color={"black"}>
                        Verify your values before adding them. You can edit the values in the
                        table to fix errors
                      </Typography>
                      <Paper style={{ border: '1px solid #ccc', maxHeight: '200px', overflowY: 'auto' }}>
                        <List>
                          {noneExistingResults.map((result, index) => (
                            <EditableRow stringResult={result} setResults={setNoneExistingResults} index={index} handleBlur={onEditValues}/>
                          ))}
                          {existingResults.map((result, index) => (
                            getItemRow( displayName ? result[displayName] : result, index)
                          ))}
                        </List>
                      </Paper>
                      </DialogContent>
                    </React.Fragment> )}/>
                  )  : (
              <React.Fragment>
              <DialogContent >
            <Typography variant="h8" color={"black"}>
              You can add values separated by commas or as a list of one per
              line
            </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  id={`list-${title}`}
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                />
        </DialogContent>
    </React.Fragment>)}
          </div>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant={"contained"}
                  sx={{
                    backgroundColor: existingResults.length === 0 ? 'blue-grey' : undefined,
                    '&:hover': {
                      backgroundColor: existingResults.length === 0 ? 'blue-grey' : undefined,
                    },
                  }}
                  onClick={() => existingResults.length === 0 ? onSelectValues(document.getElementById(`list-${title}`).value) : onSavesValues(existingResults)}
                  disabled={existingResults.length === 0 && noneExistingResults.length > 0}>{existingResults.length > 0 ? `Add ${existingResults.length} Matched Values ` : "Matched Values"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
