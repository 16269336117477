import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import BreadCrumbsAppBar from "./BreadCrumbsAppBar";
import { Link as RouterLink, Outlet } from "react-router-dom";


const drawerWidth = 200;
export interface SideMenuProps {
  menuItems: any[]
  filter?: string
  setFilter?: (value: string) => void;
  placeholder?: string;
  handleBreadcrumbClick?: (index: number) => void
  pathNames: string[]
}

export default function SideMenu(props: SideMenuProps) {
   const { handleBreadcrumbClick,pathNames } = props
  const { filter, setFilter, placeholder, menuItems } = props
  return (
    <Box>
      <CssBaseline />
      <div key={"sm-1"} style={{ position: "absolute" }}>
        <BreadCrumbsAppBar pathNames={pathNames} handleBreadcrumbClick={handleBreadcrumbClick ?? undefined} />
      </div>
      {/*<div key={"sm-2"}>*/}
      {/*  <Drawer*/}
      {/*    variant="permanent"*/}
      {/*    sx={{*/}
      {/*      width: drawerWidth,*/}
      {/*      flexShrink: 0,*/}
      {/*      [`& .MuiDrawer-paper`]: {*/}
      {/*        width: drawerWidth,*/}
      {/*        boxSizing: 'border-box',*/}
      {/*        paddingBottom: "100%",*/}
      {/*        overflow: 'hidden',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Toolbar />*/}
      {/*    <Box>*/}
      {/*      <List>*/}
      {/*        {menuItems.map((item, index) => {*/}
      {/*          const path = `/${item.name.toLowerCase().replace(/\s+/g, '-')}`;*/}
      {/*          return (<div key={`sm-i-${index}`}>*/}
      {/*            <ListItem key={decodeURIComponent(item)} disablePadding>*/}
      {/*              <ListItemButton*/}
      {/*                href={path}*/}
      {/*                onClick={() => handleBreadcrumbClick?.(index)}*/}
      {/*                >*/}
      {/*                <ListItemText primary={decodeURIComponent(item.name)} />*/}
      {/*              </ListItemButton>*/}
      {/*            </ListItem>*/}
      {/*            <Divider />*/}
      {/*          </div>*/}
      {/*        )})}*/}
      {/*      </List>*/}
      {/*    </Box>*/}
      {/*  </Drawer>*/}
      {/*</div>*/}
      <Outlet />
    </Box>
  );
}
