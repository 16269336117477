import * as _ from 'lodash';
import AuctionType from '../AuctionType';
import DealPriceStrategy from './DealPriceStrategy';
import { StrategyWrapper } from "../deal";


export interface DealPriceTypeParams {
  value: number
  text: string
  auctionType: AuctionType
}

export interface DealPriceType extends DealPriceTypeParams {}

const valueToDealPriceType: Record<number, DealPriceType> = {}


export class DealPriceType {
  static fromValue(params: number) {
    return valueToDealPriceType[params]
  }
  static NONE = new DealPriceType({
    value: 0,
    text: 'None',
    auctionType: 0
  })
  static FST_PRICE = new DealPriceType({
    value: 1,
    text: '1<sup>st</sup> Price Auction',
    auctionType: 1
  });

  static SND_PRICE = new DealPriceType({
    value: 2,
    text: '2<sup>nd</sup> Price Auction',
    auctionType: 2
  });

  static FIX_CPM = new DealPriceType({
    value: 3,
    text: 'Fixed price',
    auctionType: 3
  });

  static PUB_PRICE = new DealPriceType({
    value: 4,
    text: 'Publisher Default price',
    auctionType: 2
  });


  static isPubPrice = value => value === DealPriceType.PUB_PRICE.value;
   static fromAuctionTypeAndPriceStrategy = (auctionType: number, priceStrategy: number) => {
    switch (priceStrategy) {
      case 0:
        if(auctionType === 2) return DealPriceType.SND_PRICE
        return DealPriceType[auctionType].value
      default:
        return DealPriceType.PUB_PRICE.value;
    }
  };
  constructor(params: DealPriceTypeParams) {
    Object.assign(this, params)
    valueToDealPriceType[params.value] = this
  }

}



