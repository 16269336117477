import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@mui/material/Alert';
import { SnackbarNotificationsContext } from "./SnackbarNotificationsProvider";

const SnackbarNotifications = () => {
  const { notification, closeNotification } = useContext(SnackbarNotificationsContext);

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeNotification()
  };

  return (
    <Snackbar
      open={notification.open}
  autoHideDuration={5000}
  onClose={handleClose}
  anchorOrigin={{ vertical: "top", horizontal: "right" }}
>
  <Alert
    elevation={6}
  variant="filled"
  onClose={handleClose}
  severity={notification.type}
    >
    {notification.message}
    </Alert>
    </Snackbar>
);
};

export default SnackbarNotifications;
