import {
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  Box,
  Alert,
  Typography,
  TextField, Select, FormHelperText, Grid, Stack, Switch, Button, ListItemSecondaryAction, IconButton, FormControlLabel, Checkbox
} from "@mui/material";
import { Controller } from "react-hook-form";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import { forwardRef, useEffect, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Deal, FeesJson, StrategyType, TrackingPixels } from "./deal";
import { DealPriceType } from "./models/dealPriceType";
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { CopyText } from "../shared/CopyText";

dayjs.extend(utc);

interface BasicDealDetailsProps {
  control: any; // Replace with the correct type from react-hook-form
  fromDateValue: Date;
  formPricingModel: number;
  getValues: Function;
  setValue: Function;
  formPricingAdjustment: number;
  fees: FeesJson;
  formDealId: string;
  formAuctionType: number;
  formPriceStrategy: number;
  formDealIsPublic: boolean;
  formTrackingPixels: TrackingPixels[]
}

export enum DealType {
  "Preferred Deal" = 1,
  "Private Marketplace" = 2,
  "Public Package" = 3
}

export enum PricingModelType {
  "Fixed price" = 1,
  "Static floor" = 2,
 "Variable floor" = 3
}

export enum PricingAdjusment {
  "Default Margin" = 0,
  "Custom Margins" = 1
}


const numberToPricingModel = (type: number) => {
  return type === 1 ? "Fixed price" : type === 3 ? "Variable floor" : "Static floor";
};
const isCustomPricingAdjustment = (pricingAdjustment: PricingAdjusment) => {
  return pricingAdjustment === 1
};

const isPricingValueRequired = (type: PricingModelType) => {
  return type === 1 || type === 2;
};

const isPriceValueRequired = (type: PriceType) => {
  return type === 2;
};

export enum ViewabilityTags {
  "Exclude Viewability Tracking",
  "MOAT",
  "IAS",
  "Double Verify"
}

export const PixelType = {
  "Impressions": 0,
  "Clicks": 1,
  "Completions": 2
}

const pixelTypeToName = (pixelTypeNum: number) => {
  switch(pixelTypeNum) {
    case 0:
      return "Impressions"
    case 1:
      return "Clicks"
    case 2:
      return "Completions"
    default:
      return ""
  }
}
export const PriceType = {
  "Publisher Default price": 2,
  "Fixed price": 3
};


type FeesCallbackFunction = (feeName: string, value: number) => void


interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type DealFeesJsonPaths = `deal.fees-json.${keyof Deal["fees-json"]}`;

const NumericFormatAdjustment = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        valueIsNumericString
        suffix="%"
      />
    );
  }
);

const NumericFormatCash = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$ "
      />
    );
  }
);

const generateLabelForFeeItem = (fee: string) => {
  switch (fee) {
    case "rebate":
      return "Rebate";
    case "resellerFee":
      return "Reseller Fee";
    case "dspFee":
      return "DSP Fee";
    case "techFee":
      return "Tech Fee";
    case "postAuctionDiscount":
      return "Post Auction Discount";
    case "other":
      return "Other";
    case "baselineMargin":
      return "Baseline Margin";
    case "additionalMargin":
      return "Additional Margin";
  }
};
const generateCustomPricingFields = (fees: FeesJson, cb: FeesCallbackFunction) => {
  return Object.keys(fees).map((feeItem, index) => {
    return (
      <Grid item xs={2}>
        <TextField
          label={generateLabelForFeeItem(feeItem)}
          value={Object.values(fees)[index]}
          onChange={(e) => {
            cb(feeItem, parseFloat(e.target.value));
          }}
          name={`custom-pricing-${feeItem}`}
          id={`custom-pricing-${feeItem}-input`}
          InputProps={{
            inputComponent: NumericFormatAdjustment as any
          }}
        />
      </Grid>
    );
  });
};

// const convertDateToUTC = (date) => {
//   return dayjs(date).utc().format('YYYY-MM-DD'); // Convert the date to UTC
// };


export default function BasicDealDetails(props: BasicDealDetailsProps) {
  const {
    control,
    fromDateValue,
    formPriceStrategy,
    formDealIsPublic,
    formPricingModel,
    formAuctionType,
    formDealId,
    setValue,
    getValues,
    formPricingAdjustment,
    formTrackingPixels,
    fees
  } = props;
  useEffect(() => {
    if (formPricingAdjustment === 0) {
      Object.keys(fees).map((feeItem, index) => {
        setValue(`deal.fees-json.${feeItem}` as DealFeesJsonPaths,feeItem !== "baselineMargin" ? 0 :27)
      })
    }
  }, [formPricingAdjustment])
  const handleAddTrackingPixel = () => {
    const currPixelUrl = getValues("deal.tracking-pixels.current-pixel-url")
    const pixelType = getValues("deal.tracking-pixels.current-pixel-type")
    if(isValidUrl(currPixelUrl) === true) {
      let rawCurrentItems = getValues("deal.tracking-pixels.tracking-pixels")
      const pixelItem = {"pixel-type": pixelType, "pixel-url": currPixelUrl}
      setValue("deal.tracking-pixels.tracking-pixels", [...rawCurrentItems,pixelItem])
      setValue("deal.tracking-pixels.current-pixel-url", "")
      setValue("deal.tracking-pixels.current-pixel-type", "")
    }

  }
  const isValidUrl = (url: string) => {
    try {

      if(!(url.startsWith('http://') || url.startsWith('https://'))) {
        return "Invalid URL"
      }
      new URL(url)
      return true
    } catch (error) {
      return "Invalid URL"
    }
  }

  const deletePixelTrackingItem = (itemIndex:number) => {
    const currentItems = getValues("deal.tracking-pixels.tracking-pixels") || []

    const updatedItems = currentItems.filter((_, index:number) => index !== itemIndex)

    setValue("deal.tracking-pixels.tracking-pixels", updatedItems, { shouldValidate: true })
  };
  function renderTrackingPixelRow(props: ListChildComponentProps) {
    const { index, style } = props
    const item = formTrackingPixels["tracking-pixels"][index]
    const [copied, setCopied] = useState(false);
    const handleCopyClick = (text) => {
      navigator.clipboard.writeText(text)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    };

    if (!item) {
      return null
    }
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', flex: '1', minWidth: 0 }}>
            <ListItemText
              primary={pixelTypeToName(item["pixel-type"])}
              style={{ flex: '1', minWidth: 0 }}
            />
            <div style={{ flex: '2', minWidth: 0, marginLeft: '16px', overflow: 'hidden', marginRight: '100px' }}>
              <Typography
                variant="body1"
                noWrap
                style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {item["pixel-url"]}
              </Typography>
            </div>
          </div>
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Copy"
              size="small"
            >
              <CopyText text={item["pixel-url"]} />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => deletePixelTrackingItem(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>


    );
  }
  const getPriceAdjustmentType = (value: PricingModelType) => {
    return (value !== undefined  && formPricingModel !== 3) ? value : 0
  }
  const dealName = props.getValues().deal[`deal-free-input-name`]
  const dealPriority = props.getValues().deal[`priority`]

  return (
    <>
      <Typography variant="h6">Basic deal details</Typography>
      {formDealId !== "" ? (
        <>
          <InputLabel id={`deal-id-edit-deal`} style={{ paddingTop: "10px" }}>
            Deal Id
          </InputLabel>
          <FormControl fullWidth margin="normal">
            <TextField
              value={formDealId}
              disabled={true}
            />
          </FormControl>
        </>
      ) : null}
      {formAuctionType === 0 ? (formDealId === "" || !dealName.isDirty || dealName) && (<Controller
        name="deal.deal-free-input-name"
        control={control}
        rules={{
          required: "Deal Name is required",
          minLength: { value: 3, message: "Minimum length is 3" },
          maxLength: { value: 100, message: "Maximum length is 100" }
        } as const}
        render={({ field, fieldState }) =>
          (
            <FormControl fullWidth margin="normal">
              <TextField
                style={{ backgroundColor: "white" }}
                {...field}
                label="Deal Name"
                value={field.value}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            </FormControl>
          )}
      />) : null}
      <Controller
        name="deal.description"
        control={control}
        rules={{
          minLength: { value: 3, message: "Minimum length is 3" },
          maxLength: { value: 200, message: "Maximum length is 200" }
        }}
        render={({ field, fieldState }) => (
          <FormControl fullWidth margin="normal">
            <TextField
              {...field}
              style={{ backgroundColor: "white" }}
              label="Deal Description"
              value={field.value || ""}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ""}
            />
          </FormControl>
        )}
      />
      <Grid style={{ backgroundColor: "white", padding: "10px" }}>
      <Controller
        name={`deal.private-auction`}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth margin="normal" >
            <FormControlLabel
              control={
                <Checkbox
                  checked={(field.value)}
                  onChange={() => {
                    field.onChange(!(field.value))
                  }}
                />
              }
              label={"Private Deal"}
            />
          </FormControl>
          )}
         />
      <Controller
        name={`deal.priority`}
        control={control}
        render={() => (
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              control={
                <Checkbox
                  checked={dealPriority}
                  disabled={true}
                />
              }
              label={"Priority"}
            />
          </FormControl>
        )}
      />
      </Grid>

      <Card variant={"outlined"} style={{ marginTop: 12 }}>
        <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Deal run time"} />
        <CardContent style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Controller
            name="deal.from-date"
            control={control}
            render={({ field, fieldState }) =>
              (
              <FormControl fullWidth>
                <DatePicker
                  format="YYYY-MM-DD"
                  label={"Start Date"}
                  value={field.value ? dayjs(field.value).utc(false) : null}
                  onChange={(date: Date | null) => {
                    if (date && dayjs(date).isAfter(dayjs(), "day")) {
                      setValue("deal.active", true)
                    } else {
                      setValue("deal.active", false)

                    }
                    return field.onChange(date.toISOString())
                  }}
                  minDate={dayjs(new Date()).utc(false)}
                />
              </FormControl>
            )}
          />
          <span>To</span>
          <Controller
            name="deal.to-date"
            control={control}
            render={({ field, fieldState }) =>
              (
              <FormControl fullWidth>
                <DatePicker
                  format="YYYY-MM-DD"
                  label={"End Date"}
                  value={field.value ? dayjs(field.value).utc(false) : null}
                  onChange={(date: Date | null) => {
                    if (date && dayjs(date).isAfter(dayjs(), "day")) {
                      setValue("deal.active", true)
                    } else {
                      setValue("deal.active", false)

                    }
                    return field.onChange(date.toISOString())
                  }}
                  minDate={dayjs(fromDateValue).utc(false)}
                />
              </FormControl>
            )}
          />
        </CardContent>
      </Card>
      {(formAuctionType === 0 || formAuctionType === undefined) ? (
        <Card variant={"outlined"} style={{ marginTop: 12 }}>
          <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Pricing Model"} />
          <CardContent style={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="deal.pricing-type"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth disabled={formDealId !== ""}>
                      <InputLabel id="pricing-model-select-label">Pricing model type</InputLabel>
                      <Select
                        label={"Pricing model type"}
                        labelId={"pricing-model-select-label"}
                        id="pricing-model-select"
                        value={field.value}
                        onChange={(event) => {
                          const value = parseInt(event.target.value)
                          setValue("deal.pricing-type", value)
                          if(value === 3 ) {
                            setValue("deal.pricing-adjustment", 0)
                          }
                          field.onChange(value);
                        }}
                      >
                        {Object.keys(PricingModelType).filter((v) => isNaN(Number(v))).map((key, index) => (
                          <MenuItem value={index + 1}>{key}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                {isPricingValueRequired(formPricingModel) && (
                  <Controller
                    name="deal.bid-floor-price"
                    rules={{
                      required: "This field is required",
                      min: { value: 0.01, message: "Pricing model floor price should be at least 0.01" },
                      max: { value: 100, message: "Pricing model floor price should be at most 100" }
                    }}
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          InputProps={{
                            inputProps: {
                              min: 0.01,
                              max: 100
                            },
                            inputComponent: NumericFormatCash as any
                          }}

                          label={formPricingModel === PricingModelType["Fixed price"] ? "Fixed price value" : "Static floor value"}
                          onChange={(event) => {
                            const value = parseFloat(event.target.value);
                            setValue("deal.bid-floor-price", value);
                            field.onChange(value);
                          }}
                        />
                        {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="deal.pricing-adjustment"
                  control={control}
                  render={({ field }) => {
                    const pricingAdjustmentValue = getPriceAdjustmentType(field.value)
                    return (

                      <FormControl fullWidth disabled={formPricingModel === 3}>
                        <InputLabel id="pricing-adjustment-select-label">Pricing Adjustment type</InputLabel>
                        <Select
                          labelId="pricing-adjustment-select-label"
                          id="pricing-adjustment-select"
                          label="Pricing Adjustment type"
                          value={pricingAdjustmentValue}
                          {...field}
                        >
                          {Object.keys(PricingAdjusment).filter((v) => isNaN(Number(v))).map((key, index) => (
                            <MenuItem value={PricingAdjusment[key]} key={index}>{key}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    );
                  }}
                />
              </Grid>
            </Grid>

            {isCustomPricingAdjustment(formPricingAdjustment) && (
              <Box style={{ marginTop: 16 }}><Grid container spacing={{ xs: 2, md: 3 }}
                                                   columns={{ xs: 4, sm: 8, md: 12 }}>

                {generateCustomPricingFields(fees, (feeName, newValue) => {
                  const current = getValues(`deal.fees-json`) as DealFeesJsonPaths;
                  current[feeName] = newValue;
                  const sumOfFees: number = Object.values(current).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
                  if (sumOfFees > 100 || sumOfFees < 0) {
                    console.error("Total fees cannot exceed 100");
                    setValue(`customMarginError`, "Total fees cannot exceed 100");
                    setValue(`deal.fees-json.${feeName}` as DealFeesJsonPaths, newValue);
                  } else {
                    setValue(`customMarginError`, null);
                    setValue(`deal.fees-json.${feeName}` as DealFeesJsonPaths, newValue);
                  }
                })}

              </Grid>
                {getValues(`customMarginError`) && (
                  <Alert style={{ margin: 12 }} severity="error">{getValues(`customMarginError`)}</Alert>)}
              </Box>
            )}

          </CardContent>
        </Card>) : (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Pricing</span>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Controller
                name="deal.auction-type"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControl fullWidth>
                      <Select
                        value={formPriceStrategy ? DealPriceType.fromAuctionTypeAndPriceStrategy(field.value, formPriceStrategy) : DealPriceType.fromAuctionTypeAndPriceStrategy(2, 1)}
                        labelId="price-model-select-label"
                        id="price-model-select"
                        // label="Pricing model"
                        {...field}
                      >
                        {Object.keys(PriceType).map((key, index) => (
                          <MenuItem value={PriceType[key]}>{key}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              />
              {isPriceValueRequired(formAuctionType) && (
                <Controller
                  name="deal.bid-floor-price"
                  control={control}
                  rules={{
                    min: { value: 0.01, message: "Pricing model floor price should be at least 0.01" },
                    max: { value: 100, message: "Pricing model floor price should be at most 100" }
                  }}
                  render={({ field }) => (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          InputProps={{
                            inputProps: {
                              min: 0.01
                            },
                            inputComponent: NumericFormatCash as any
                          }}
                          variant="standard"
                          onChange={(event) => {
                            const value = parseFloat(event.target.value);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                />
              )
              }
            </div>
          </div>
        </>
      )
      }


      <Controller
        name="deal.viewability-tag"
        control={control}
        render={({ field }) => (
            <FormControl fullWidth style={{marginTop: 16}}>
              <InputLabel htmlFor="viewability-tag-select-label" >Viewability Tag</InputLabel>
              <Select
                label={"Viewability Tag"}
                {...field}
                labelId="viewability-tag-select-label"
                id="viewability-tag-select"
                style={{backgroundColor: 'white'}}
              >
                {Object.entries(ViewabilityTags)
                  .filter(([key, value]) => !isNaN(Number(value)))
                  .map(([key, value]) => (
                    <MenuItem value={value} key={key}>
                      {key}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

        )}
      />
      <Card variant="outlined" style={{ marginTop: 12 }}>
        <CardHeader titleTypographyProps={{ variant: "caption" }} title="Add Tracking Tag(s)" />
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Grid container direction="column" alignItems="start" spacing={2} >
            <Grid container direction="row"  >
              <Grid item xs={2}>
              <Controller
                name="deal.tracking-pixels.current-pixel-type"
                control={control}
                render={({ field }) => (
              <FormControl style={{ width: "90%", marginLeft: 15 }}>
                <InputLabel htmlFor="pixel-types-select-label">Pixel Type</InputLabel>
                <Select
                  label="Pixel Type"
                  labelId="pixel-types-select-label"
                  id="pixel-types-select"
                  {...field}
                >
                  {Object.keys(PixelType).filter((key) => key !== "None").map((key, index) => (
                    <MenuItem key={index} value={PixelType[key]} disabled={formTrackingPixels["tracking-pixels"] && formTrackingPixels["tracking-pixels"].filter(item => item["pixel-type"] === PixelType[key]).length > 0}>{key}</MenuItem>
                  ))}
                </Select>
              </FormControl>)}
             />
              </Grid>
              <Grid item xs={9}>
              <Controller
                name="deal.tracking-pixels.current-pixel-url"
                control={control}
                rules={{
                  validate: getValues("deal.tracking-pixels.current-pixel-url") !== "" && isValidUrl,
                } as const}
                render={({ field, fieldState }) =>
                  (
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        id={`tracking-pixel-url}`}
                        type="text"
                        rows={1}
                        label={"Pixel URL"}
                        style={{width: "100%"}}
                        {...field}
                        />
                      {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}

                    </FormControl>
                  )}
              />
              </Grid>
              <Grid item xs={1}>
              <Button variant="contained"  onClick={handleAddTrackingPixel} disabled={getValues("deal.tracking-pixels.current-pixel-url") === undefined || getValues("deal.tracking-pixels.current-pixel-url") === "" || getValues("deal.tracking-pixels.current-pixel-type") === ""  }
                style={{height: "56px", width: "70%", marginLeft: 30}}>
                Add
              </Button>
              </Grid>
            </Grid>
            {formTrackingPixels["tracking-pixels"].length > 0 && (
              <Grid item style={{ width: '100%' }}>
                <FixedSizeList
                  height={200}
                  itemSize={46}
                  style={{ border: `1px solid black`, margin: '10px 0' }}
                  itemCount={formTrackingPixels["tracking-pixels"].length }
                  overscanCount={5}
                  itemData={formTrackingPixels["tracking-pixels"].length}
                >
                  {renderTrackingPixelRow}
                </FixedSizeList>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>

  );
}
