import { useEffect } from "react";

interface Props {
  keys: string[] | null
  onPress: () => void
}

export default function useShortcut({ keys, onPress }: Props) {
  function handleKeyup(event: KeyboardEvent) {
    if (keys === null) {
      return
    }

    if (document.activeElement?.tagName === 'INPUT') {
      return
    }

    if (event.key === keys[0]) {
      onPress()
    }
  }

  useEffect(() => {
    if (keys === null) {
      return
    }

    document.addEventListener('keyup', handleKeyup)
    return () => document.removeEventListener('keyup', handleKeyup)
  }, [])
}
