import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CopyToClipboard from "react-copy-to-clipboard";

const TOOLTIP_DURATION = 1_000 // ms

interface CopyTextProps {
  text: string
}

export  function CopyText(props: CopyTextProps) {
  const [copied, setCopied] = useState<boolean>(false)

  return (
    <CopyToClipboard text={props.text} onCopy={() => {
      setCopied(true)
      setTimeout(() => setCopied(false), TOOLTIP_DURATION)}
    }>
      <Tooltip open={copied} title="Copied">
        <IconButton edge="end" aria-label="copy">
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  )
}
