import { SortingState } from '@tanstack/react-table'

export type DealSort = {
  field: 'state' | 'dealId' | 'dealFreeInputName' | 'fromDate' | 'toDate'
  order: 'asc' | 'desc'
}

export function getDealSort(sorting: SortingState): DealSort {

  const column = sorting.at(0)

  if(column === undefined) {
    const field = 'dealId'
    const order = 'desc'
    return {field, order}
  }

  const field = ((column?.id as DealSort['field']) ||
    'dealId') as DealSort['field']
  const order = (column?.desc ? 'desc' : 'asc') as DealSort['order']

  return { field, order }
}
