import DealsLayout from "./DealsLayout";
import { ReactNode } from "react";


export interface ScreenWrapperProps {
  children: ReactNode
  menuItems: string[]
}



const ScreenWrapper = (props: ScreenWrapperProps) => {
  const {children,menuItems} = props



  return (
    <DealsLayout menuItems={menuItems}>
      {children}
    </DealsLayout>
  );
};

export default ScreenWrapper
