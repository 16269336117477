import MoreVert from "@mui/icons-material/MoreVert";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export interface ActionsCellProps {
  row: any;
  actionsNames: string[];
  callbackActions: any;
}

export function ActionsCell(props: ActionsCellProps) {
  const { row, actionsNames, callbackActions } = props;

  // Menu
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string>(''); // Track selected action

  const handleActionClick = (actionName: string) => {
    if (actionName === 'Activate Deal' || actionName === 'Stop Deal') {
      setSelectedAction(actionName); // Set the selected action
      setMenuAnchor(null);
      setConfirmationDialogOpen(true);
    } else {
      callbackActions[actionsNames.indexOf(actionName)](row);
      setMenuAnchor(null);
    }
  };

  const handleConfirmationDialogClose = (confirmed: boolean) => {
    setConfirmationDialogOpen(false);

    if (confirmed && selectedAction) {
      // Implement your logic to change the status here
      if (selectedAction === 'Activate Deal') {
        // Handle "Activated" action
      } else if (selectedAction === 'Stop Deal') {
        // Handle "Stopped" action
      }
    }

    setSelectedAction(''); // Reset selected action
  };

  return (
    <>
      <IconButton
        id="action-button"
        aria-haspopup="true"
        aria-controls={Boolean(menuAnchor) ? 'action-menu' : undefined}
        aria-expanded={Boolean(menuAnchor)}
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          setMenuAnchor(event.currentTarget);
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="action-menu"
        MenuListProps={{
          'aria-labelledby': 'action-button',
        }}
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onBackdropClick={(event) => event.stopPropagation()}
        onClose={() => setMenuAnchor(null)}
      >
        {actionsNames.map((item, index) => (
          <MenuItem
            key={item}
            onClick={(event) => {
              event.stopPropagation();
              handleActionClick(item); // Pass the action name to the click handler
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationDialogOpen} onClose={() => handleConfirmationDialogClose(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to {selectedAction === 'Activate Deal' ? 'activate this deal' : selectedAction === 'Stop Deal' ? 'stop this deal' : ''}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmationDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmationDialogClose(true)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
