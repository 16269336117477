import { useFormContext } from "react-hook-form";
import { StrategyType } from "./deal";
import { Button, ButtonGroup } from "@mui/material";
import { CSSProperties, Children } from "react";

export const IncludeExcludeOverlay = (props) => {
  const methods = useFormContext()
  const { strategyKey, name } = props

  const editing = methods.getValues("editing")
  const resolved:string[] = methods.getValues("resolvedStrategies")

  const onChosenStrategy = (strategy:StrategyType) => {
    return () => {
      methods.setValue(strategyKey, strategy)
      methods.setValue('resolvedStrategies', [...resolved, strategyKey])
    }
  }

  const shouldDisplayOverlay = editing && resolved.find(it => it === strategyKey) === undefined
  const overlayDivDisplayValue = shouldDisplayOverlay ? "flex" : "none"
  const wrappedElementStyle:CSSProperties = {position: "relative", opacity: shouldDisplayOverlay ? 0.1 : 1.0}
  if (shouldDisplayOverlay) {
    wrappedElementStyle["pointerEvents"] = 'none'
  }
  return (
    <div id={`parent-container-${strategyKey}`} style={{width: "100%", padding: 0, position: "relative"}}>
      <div id={`ov-${strategyKey}`} style={{position: "absolute",
        display: overlayDivDisplayValue, backgroundColor:"#FdFdFd",
        alignItems: "center",
        justifyContent: "center",
        height: "100%", width:"100%", flexDirection: 'column'}}>
        <h3 style={{
          flex: 0,
          padding: "10px"}}>First, choose a strategy for {name}</h3>
        <ButtonGroup
          style={{flex: 0}}
          size="large"
          variant="outlined"
          aria-label="Include or exclude"
        >
          <Button color={"secondary"} onClick={onChosenStrategy(StrategyType.include)}>Include</Button>
          <Button color={"secondary"} onClick={onChosenStrategy(StrategyType.exclude)}>Exclude</Button>
        </ButtonGroup>
      </div>
      <div id={`con-${strategyKey}`} style={wrappedElementStyle} >
        {...Children.toArray(props.children)}
      </div>
    </div>
  )
}
