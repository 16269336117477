import { createTheme } from '@mui/material'

export const colors = {
  // DT colors

  softBlack: '#231F20',
  black: '#000',
  white: '#fff',

  // Dark Blue
  plum: '#251546',

  softCharcoal: {
    0: '#eeeff4',
    100: '#dddfe8',
    200: '#caccdb',
    300: '#b6b9ce',
    400: '#a3a6c0',
    500: '#8f93b3',
    600: '#686d99',
    700: '#484d80',
    800: '#2d3267',
    900: '#1b1f55',
  },

  softBlue: {
    0: '#eef2f7',
    100: '#dbe3ee',
    200: '#c8d5e6',
    300: '#b5c6dd',
    400: '#a2b8d5',
    500: '#8fa9cc',
    600: '#6883af',
    700: '#486192',
    800: '#2d4376',
    900: '#1b2d61',
  },

  // Turbine Red
  red: {
    0: '#f9dde3',
    50: '#f6cdd6',
    100: '#f1acbb',
    200: '#ec8ca0',
    300: '#e76b85',
    400: '#e24b6a',
    500: '#da1a42',
    600: '#c4173b',
    700: '#ae1535',
    800: '#99122e',
    900: '#831028',
  },

  // Ignite Orange
  orange: {
    500: '#f05532',
  },

  // Monetization Purple
  purple: {
    0: '#eee2f1',
    50: '#e3cee8',
    100: '#d7bbdf',
    200: '#cca7d6',
    300: '#b783c5',
    400: '#a360b5',
    500: '#8e3ca4',
    600: '#803694',
    700: '#723083',
    800: '#632a73',
    900: '#552462',
  },

  // Golden Yellow
  yellow: {
    0: '#fff5dc',
    50: '#fff0ca',
    100: '#ffe6a5',
    200: '#ffde89',
    300: '#ffd25d',
    400: '#ffc839',
    500: '#ffbe14',
    600: '#b0610c',
    700: '#94510a',
    800: '#80470a',
    900: '#6d3c09',
  },

  // Grass Blade
  green: {
    0: '#ebf3e1',
    50: '#e0ecd1',
    100: '#d3e4be',
    200: '#c6dcaa',
    300: '#b9d597',
    400: '#abcc82',
    500: '#99c167',
    600: '#5e7f34',
    700: '#55722f',
    800: '#425924',
    900: '#384c1f',
  },

  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eaeaea',
    300: '#e1e1e1',
    400: '#cacaca',
    500: '#b3b3b3',
    600: '#8e8e8e',
    700: '#6e6e6e',
    800: '#4b4b4b',
    900: '#2c2c2c',
  },

  alpha: {
    200: 'rgba(0, 0, 0, 0.02)',
  },
}

// https://material.io/design/motion/speed.html#duration
export const transitions = {
  sm: '100ms',
  md: '200ms',
  lg: '300ms',
}

export const radiuses = {
  sm: '2px',
  md: '4px',
  lg: '8px',
  full: '100%',
}

export const shadows = {
  sm: '0px 1px 2px rgba(0, 0, 0, 0.05);',
  base: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);',
  md: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);',
  lg: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);',
}

export const fonts = {
  body: {
    xs: { fontSize: '10px', lineHeight: '14px' },
    sm: { fontSize: '12px', lineHeight: '16px' },
    md: { fontSize: '14px', lineHeight: '20px' },
    lg: { fontSize: '16px', lineHeight: '24px' },
  },
  display: {
    sm: { fontSize: '24px', lineHeight: '32px' },
    md: { fontSize: '26px', lineHeight: '32px' },
    lg: { fontSize: '28px', lineHeight: '32px' },
  },
  heading: {
    xs: { fontSize: '14px', lineHeight: '24px' },
    sm: { fontSize: '16px', lineHeight: '24px' },
    md: { fontSize: '18px', lineHeight: '24px' },
    lg: { fontSize: '20px', lineHeight: '24px' },
    xl: { fontSize: '24px', lineHeight: '32px' },
  },
  overline: {
    sm: { fontSize: '10px', lineHeight: '12px' },
    md: { fontSize: '12px', lineHeight: '16px' },
    lg: { fontSize: '14px', lineHeight: '20px' },
  },
  label: {
    sm: { fontSize: '12px', lineHeight: '16px' },
    md: { fontSize: '14px', lineHeight: '20px' },
    lg: { fontSize: '16px', lineHeight: '20px' },
  },
}

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  extrabold: 900,
}

// there's never a letter spacing if weight is "light"
export const letterSpacings = {
  body: { xs: null, sm: '0.25px', md: '0.25px', lg: '0.25px' },
  display: { xs: null, sm: null, md: null, lg: null },
  heading: { xs: '0.25px', sm: '0.25px', md: null, lg: null, xl: null },
  overline: { sm: null, md: null, lg: null },
  label: { sm: null, md: '0.25px', lg: null },
}

export const palette = {
  primary: {
    light: colors.purple[0],
    main: colors.purple[500],
    dark: colors.purple[900],
    contrastText: colors.white,
  },
  secondary: {
    light: colors.softCharcoal[0],
    main: colors.softCharcoal[700],
    contrastText: colors.white,
  },
  success: {
    light: colors.green[50],
    main: colors.green[900],
    dark: colors.green[500],
  },
  controlError: {
    main: colors.yellow[500],
  },
  error: {
    light: colors.red[0],
    main: colors.red[900],
    dark: colors.red[500],
  },
  warning: {
    light: colors.yellow[0],
    main: colors.yellow[900],
    dark: colors.yellow[500],
  },
  info: {
    light: colors.softCharcoal[0],
    main: colors.softCharcoal[900],
    dark: colors.softCharcoal[500],
  },
  background: { default: colors.gray[100], paper: colors.white },
  text: { primary: colors.gray[900] },
}

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: ["Inter", "Roboto", "sans-serif"].join(",")
  },
  components: {
    MuiAlert: {
      defaultProps: {
        role: 'alert',
      },
      styleOverrides: {
        outlinedSuccess: {
          backgroundColor: palette.success.light,
          color: palette.success.main,
          border: `1px solid ${palette.success.dark}`,
          '.MuiAlert-icon': {
            color: palette.success.main,
          },
        },
        outlinedError: {
          backgroundColor: palette.error.light,
          color: palette.error.main,
          border: `1px solid ${palette.error.dark}`,
          '.MuiAlert-icon': {
            color: palette.error.main,
          },
          '& .MuiLink-root': {
            color: palette.error.main,
            textDecorationColor: palette.error.main,
          },
        },
        outlinedWarning: {
          backgroundColor: palette.warning.light,
          color: palette.warning.main,
          border: `1px solid ${palette.warning.dark}`,
          '.MuiAlert-icon': {
            color: palette.warning.main,
          },
        },
        outlinedInfo: {
          backgroundColor: palette.info.light,
          color: palette.info.main,
          border: `1px solid ${palette.info.dark}`,
          '.MuiAlert-icon': {
            color: palette.info.main,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: '1px solid #b3b3b3'
        },
        underline: {
          "&$focused:after": {
            borderBottomColor: `#8E3CA4;`
          },
          "&:before": {
            borderBottom: `1px solid #c5d0de`
          },
          "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: `2px solid #c5d0de`
          }
        },
        input: {
          color: colors.black,
          "&:-webkit-autofill": {
            transition: "background-color 9999s ease-in-out 0s",
            WebkitTextFillColor: colors.black,
            transitionDelay: "9999s",
            "&:hover, &:focus, &:active": {
              transition: "background-color 9999s ease-in-out 0s",
              WebkitTextFillColor: colors.black,
              transitionDelay: "9999s"
            }
          }
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        position: 'sticky',
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          color: colors.gray[900],
          boxShadow: shadows.base,
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '9px 13px',
          textTransform: 'initial',
          fontWeight: 500,
          letterSpacing: 0.25,
          lineHeight: '20px',
        },
        sizeSmall: {
          fontSize: '11px',
          lineHeight: '14px',
        },
        outlinedPrimary: {
          backgroundColor: palette.primary.main,
          padding: '8px 12px',
          '&.Mui-disabled': {
            color: palette.primary.main,
            borderColor: palette.primary.main,
          },
        },
        containedPrimary: {
          backgroundColor: palette.primary.main,
          color: colors.white,
          padding: '8px 12px',
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.primary.light,
            color: colors.softCharcoal[200],
          },
        },
        textPrimary: {
          color: colors.softBlack,
          backgroundColor: 'transparent',
          '&:hover': {
            color: colors.softCharcoal[500],
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            color: colors.softCharcoal[200],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.gray[100],
        },
      },
    },

    MuiDrawer: {
      defaultProps: {
        variant: 'permanent',
      },
      styleOverrides: {
        root: {
          width: 300,
          zIndex: 100,
        },
        paper: {
          position: 'relative',
          right: 0,
          bottom: 0,
          border: 'none',
          boxShadow: shadows.md,
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontSize: fonts.body.md.fontSize,
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.primary.main,
        },
        root: {
          fontSize: fonts.body.md.fontSize,
          color: colors.gray[900],
          '&.Mui-focused': {
            color: colors.gray[900],
          },
          '&.Mui-error': {
            color: colors.gray[900],
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: colors.yellow[500],
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1050,
        },
      },
    },

    // MuiInputLabel: {
    //   styleOverrides: {
    //     asterisk: {
    //       color: palette.primary.main,
    //     },
    //     standard: {
    //       fontSize: 14,
    //       transform: 'scale(1)',
    //     },
    //   },
    // },

    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            marginTop: 8,
            boxShadow: shadows.lg,
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 8,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: 24,
          marginRight: 24,
        },
        indicator: {
          height: 4,
        },
        flexContainer: {
          gap: 24,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          paddingRight: 0,
          paddingLeft: 0,
          color: colors.gray[600],
          textTransform: 'initial',
          fontSize: 14,
          lineHeight: '20px',
          letterSpacing: 0.25,

          '&.Mui-selected': {
            fontWeight: 600,
            color: colors.gray[900],
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid red', // Change the color to your desired color
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: 16,
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.15px',
          backgroundColor: 'white',
          borderTopLeftRadius: radiuses.md,
          borderTopRightRadius: radiuses.md,

          '&:first-of-type': {
            paddingLeft: 24,
          },
          '&:last-of-type': {
            paddingRight: 24,
          },
        },
        root: {
          borderTopLeftRadius: radiuses.md,
          borderTopRightRadius: radiuses.md,
          padding: '12px 16px',
          '&:first-of-type': {
            paddingLeft: 24,
          },
          '&:last-of-type': {
            paddingRight: 24,
          },
        },
      },
    },

    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       transition: `background-color ${transitions.sm}`,
    //       '&:hover': {
    //         backgroundColor: colors.alpha[200],
    //       },
    //     },
    //   },
    // },

    MuiTablePagination: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          width: 25,
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: null,
              cursor: 'default',
            },
            '&.MuiToggleButton-success': {
              backgroundColor: palette.success.light,
            },
            '&.MuiToggleButton-error': {
              backgroundColor: palette.error.light,
            },
            '&.MuiToggleButton-secondary': {
              color: colors.white,
              backgroundColor: palette.secondary.main,
            },
          },
        },
      },
    },
  },
})
