import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import CustomMenuItem from "./CustomMenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";


const StyledAppBar = styled(AppBar)`
  background-color: #0000;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: none;
`;

interface BundlesTableAppbarProps {
  numOfSelected: number
}

function BundlesTableAppbar(props: BundlesTableAppbarProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <StyledAppBar position="static">
      <Container maxWidth="xl" >
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              // anchorOrigin={{
              //   // vertical: 'bottom',
              //   horizontal: 'left',
              // }}
              keepMounted
              // transformOrigin={{
              //   vertical: 'top',
              //   horizontal: 'left',
              // }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            </Menu>
          <Box>
            <CustomMenuItem text={` ${props.numOfSelected} selected`}/>
            <CustomMenuItem text={"Clear Selection"} onClick={() => {console.log("clear")}}/>
            <CustomMenuItem text={"Remove Selected IDs"} icon={<DeleteIcon/>}  onClick={() => {console.log("remove")}}/>
            <CustomMenuItem text={"Download selection as CSV"} icon={<DownloadIcon/>} onClick={() => {console.log("download")}}/>
            <CustomMenuItem text={"Copy selected IDs"} icon={<ContentCopyIcon/>} onClick={() => {console.log("download")}}/>
          </Box>
      </Container>
    </StyledAppBar>
  );
}
export default BundlesTableAppbar;
