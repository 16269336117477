import { Controller } from 'react-hook-form';
import Divider from "@mui/material/Divider";
import { StrategyType } from "./deal";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button, CardHeader,
  FormControl, FormHelperText,
  IconButton,
  InputLabel,
  ListItemSecondaryAction,
  Select,
  Stack,
  Card,
  CardContent,
  Switch,
  Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import LanguageSearchAutocomplete from "./languageSearchAutoComplete";
import BulkDialog from "../shared/bulkDialog";
import { IncludeExcludeOverlay } from "./strategyOverlay";

export const DeviceOS  = {
  iOS: "IOS",
  Android: "Android",
  all: "All",
}

export const DeviceType = {
  all: "All",
  tablet: "Tablet",
  phone: "Phone",
}


export interface LanguageItem {
  "languages-code": string
  "languages-name": string
}

interface DeviceDataTargetingProps {
  control: any; // Replace with the correct type from react-hook-form
  selectedLanguages: any
  setValue: Function
  getValues: Function
  strategyLanguagesValue: StrategyType

}


export default function DeviceDataTargeting( props:DeviceDataTargetingProps) {
  const { control, setValue, selectedLanguages, strategyLanguagesValue } = props
  const [openDialog, setOpenDialog] = useState(false)
  const [languages,setLanguages] = useState<LanguageItem[]>([])
  const languageName = "Languages";
  const fetchExistingLanguages = async () => {
    const encodeLanguages = selectedLanguages.map(encodeURIComponent)
    const response = await fetch(`/api/deals-aux/get-existing-languages?q=${encodeLanguages}`, {
      method: "get",
      mode: "cors"
    })
    const json = await response.json();
    return json
  }
  const deleteLanguageItem = (itemIndex:number) => {
    // Get the current array of geo items from the form state
    const currentItems = languages || [];

    // Filter out the item to delete
    const updatedItems = currentItems.filter((_, index) => index !== itemIndex);
    // Update the form state with the new array of items
    setLanguages(updatedItems)
    setValue("deal.conditions-json.language.value", updatedItems.map((item) => item["languages-code"]), { shouldValidate: true });
  };
  useEffect(() => {
    const fetchData = async () => {
      const result: LanguageItem[] = await fetchExistingLanguages()
      setLanguages(result)
    };
    selectedLanguages.length !== 0 ? fetchData() : null
  }, []);

  function renderLanguageRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const item = languages[index];
    if (!item) {
      return null;
    }

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={item["languages-name"]} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => deleteLanguageItem(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  }

  const [languageAutocompleteKey, setLanguageAutocompleteKey] = useState(0);

  const fetchBulkData = async (parsedValues: string[]) => {
    try {
      const response = await fetch(`/api/deals/get-bulk-languages`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(parsedValues),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      return responseData

    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const handleLanguageSelect = (item: LanguageItem | null) => {
    if (item) {
      const currentItems = languages || [];
      const isDuplicate = currentItems.some(existingItem => existingItem["languages-code"] === item["languages-code"]);
      if (!isDuplicate) {
        setLanguages(prevLanguages => {
          const codeItems = prevLanguages.map((item) => item["languages-code"])
          setValue("deal.conditions-json.language.value", [...codeItems, item["languages-code"]])
          return [...prevLanguages, item]
        })
        setLanguageAutocompleteKey(0)
      } else {

        setLanguageAutocompleteKey(prev => prev +1)
      }
    }
  };

  return (
    <Card variant={"outlined"} style={{marginTop:12}}>
        <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Device Data Targeting"} />
        <CardContent>
      <IncludeExcludeOverlay {...props} strategyKey={"deal.conditions-json.language.strategy"} name={languageName} >
      <Typography style={{paddingTop: 12, paddingBottom:24}}>Languages</Typography>
    <Stack direction="row" spacing={1} alignItems="center">
      <Controller
        name="deal.conditions-json.language.strategy"
        control={control}
        render={({ field }) => (
          <FormControl style={{width: 140}}>
            <InputLabel id="device-targeting-include-exclude-label">Include/Exclude</InputLabel>
          <Select labelId={"device-targeting-include-exclude-label"} id={"device-targeting-include-exclude"} {...field} value={field.value} label="Include/Exclude" onChange={() => {
            const value = field.value === StrategyType.include
              ? StrategyType.exclude
              : StrategyType.include;
            field.onChange(value);
          }}>
            <MenuItem value={StrategyType.include}>Include</MenuItem>
            <MenuItem value={StrategyType.exclude}>Exclude</MenuItem>
          </Select>
          </FormControl>
        )}
      />
      <Controller
        name="deal.conditions-json.language.value"
        control={control}
        render={({ field }) => (
          <>
          <LanguageSearchAutocomplete
            key ={languageAutocompleteKey}
            selectedOption={languages}
            onOptionSelect={(item) => handleLanguageSelect(item)}
          />
          <Button onClick={() => setOpenDialog(true)}>
        Add device languages in bulk
      </Button>
      {openDialog ? (
        <BulkDialog open={openDialog} displayName={"languages-name"} fetchData={fetchBulkData}  setOpen={setOpenDialog} title={"Device Languages"} control={control} selectedData={selectedLanguages}  handleSave={handleLanguageSelect}/>
      ) : null}

          </>
        )}
      />
    </Stack>
  <FixedSizeList height={300}
                 itemSize={46}
                 style={{ border: `1px solid ${strategyLanguagesValue === 'exclude'  ? 'red' : strategyLanguagesValue === 'include' ? 'green' : "black"}`, margin: '10px 0' }}
                 itemCount={languages ? languages.length : selectedLanguages.length}
                 overscanCount={5}
                 itemData={languages}>
    {renderLanguageRow}
  </FixedSizeList>
      </IncludeExcludeOverlay>
  <Controller
    name="deal.conditions-json.deviceOS.value"
    control={control}
    render={({ field, fieldState }) => {
      return (
      <>
        <FormControl fullWidth margin="normal">
          <InputLabel id="deviceOs-tag-select-label" >Device OS</InputLabel>
        <Select
          labelId="deviceOs-tag-select-label"
          label="Device OS"
          error={!!fieldState.error}
          value={field.value ?? "all"}
          onChange={(e) => {
            field.onChange(e);
          }}
            ref={field.ref}
        >
          {Object.keys(DeviceOS).map((key, index) => (
            <MenuItem value={key} key={index}>
              {DeviceOS[key]}
            </MenuItem>
          ))}
        </Select>

        {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}

        </FormControl>
      </>
    )}}
  />
  <Controller
    name="deal.conditions-json.deviceType.value"
    control={control}
    render={({ field, fieldState }) => (
      <>
        <FormControl fullWidth margin="normal">
        <InputLabel id="deviceType-tag-select-label" style={{ paddingTop: "10px"}}>Device Type</InputLabel>
        <Select
          labelId="deviceOs-tag-select-label"
          label="Device Type"
          error={!!fieldState.error}
          value={field.value ?? "all" }
          onChange={(e) => {
            field.onChange(e);
          }}
          ref={field.ref}
        >
          {
            Object.keys(DeviceType).map((key, index) => (
              <MenuItem value={key} key={index}>
                {DeviceType[key]}
              </MenuItem>
            ))}
        </Select>
        {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
        </FormControl>
      </>
    )}
  />
          </CardContent>
      </Card>
  )
}
