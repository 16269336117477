import { useEffect, useState, Fragment, createRef, useRef } from "react";
import { Autocomplete, CircularProgress, Popover, Popper, TextField } from "@mui/material";

interface CountryAutocompleteProps {
  onOptionSelect: (item: string | null) => void;
  selectedOption?: string | null;
}

export default function CountrySearchAutocomplete(props: CountryAutocompleteProps) {
  const {onOptionSelect, selectedOption} = props
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("")

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const getNoOptionsText = () => {
    if (loading) return "Loading...";
    if (!inputValue.trim()) return "Start typing to view results";
    if (dataFetched && options.length === 0) return `No results for "${inputValue}"`;
    return null;
  };

  const fetchData = async (searchTerm:string) => {
    const response = await fetch(`/api/geo/get-countries?q=${encodeURIComponent(searchTerm)}`, {
      method: "get",
      mode: "cors"
    })
    const json = await response.json();
    if(!json){
      return []
    }
    return json
  }

  useEffect(() => {
    let active = true;

    if (searchTerm === "") {
      setOptions([]);
      setDataFetched(false);
      setLoading(false);
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const results = await fetchData(searchTerm);

        if (active) {
          setOptions(results);
          setDataFetched(true);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchTerm]);


  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const inputRef = useRef(null);
  return (
    <Autocomplete
      id="asynchronous-zip-code-countries"
      sx={{ width: 300 }}
      PopperComponent={(popperProps) => (
        <Popper
          {...popperProps}
          style={{ width: '300px', zIndex: 2000 }}
          anchorEl={inputRef.current}
          placement="bottom-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [-10, 12],
              },
            },
          ]}
        />
      )}
      open={open}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedOption ?? null}
      onChange={(_, newValue) => {
        onOptionSelect(newValue);
      }}
      getOptionLabel={(option) => option || ''}
      isOptionEqualToValue={(option, value) => option === value}
      options={options}
      loading={loading}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        setSearchTerm(newInputValue);

        if (newInputValue.trim() === "") {
          setDataFetched(false);
          setOptions([]);
        }
      }}
      filterOptions={(x) => x}
      noOptionsText={getNoOptionsText()}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          style={{ width: '300px' }}
          label="Enter a country"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}