import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

interface Props {
  cells: number
}

export default function SkeletonRow({ cells }: Props) {
  return (
    <TableRow className="skeleton-row">
      {new Array(cells).fill(0).map(() => (
        <TableCell key={`skeleton-cell`}>
          <Skeleton variant="rectangular" width="100%" height="27px" />
        </TableCell>
      ))}
    </TableRow>
  )
}
