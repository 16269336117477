import * as React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, FormatListBulleted as FormatListBulletedIcon } from "@mui/icons-material";

export interface ExportListDropDownProps {

}

export function ExportListDropDow(props: ExportListDropDownProps) {
    const [anchorElement, setAnchorElement] = React.useState(null);
  const isOpen = Boolean(anchorElement);

    const handleMenuOpen = (event) => {
      setAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorElement(null);
    };

    const handleDownloadCSV = () => {
      handleMenuClose();
    };

    const handleCopyBundlesID = () => {
      handleMenuClose();
    };

    return (
      <div>
        <Button
          id="export-list-button"
          aria-controls={isOpen ? "export-list-menu" : undefined}
          // aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          onClick={handleMenuOpen}
          variant="outlined"
          color={"primary"}
          endIcon={<ExpandMoreIcon />}>
          <FormatListBulletedIcon />
          Export list
        </Button>
        <Menu
          id="export-list-menu"
          anchorEl={anchorElement}
          open={isOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'export-list-button',
        }}
          PaperProps={{
            style: {
              marginTop: "50px",
              paddingRight: "10px"
            },
          }}
        >
          <MenuItem onClick={handleDownloadCSV}>Download as CSV</MenuItem>
          <MenuItem onClick={handleCopyBundlesID}>Copy Bundle IDs</MenuItem>
        </Menu>
      </div>
    );
}
