import Table from "./Table";
import { BundleListNameCell } from "./BundleListNameCell";
import { ActionsCell } from "./ActionsCell";
import { useState } from "react";
import { Grid, Paper } from "@mui/material";
import BeforeTable from "./BeforeTable";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";

interface Props {
  filter?: string
  store: any
}

export default function BundleListTable(props: Props) {
  const [filter, setFilter] = useState("")
  const bundles = props.store.addQuery("bundleList", async () => {
    const response = await fetch("/bundleList");
    const data = await response.json();
    return data
  })
  const data = bundles.data ?? []

  // const { selectedRow, setSelectedRow,history} = useContext(AppContext)!

  const handleRowClick = (bundleList: any) => {
    // setSelectedRow(bundleList)
  }
  const columns = [
    { id: 'bundleListName',accessor:'bundleListName', header: 'Bundle List Name',cell: ({ row }) => {
        return (<div style={{color: 'inherit', textDecoration: "none"}}><Link to={`/bundle-lists/${row.original.bundleListName.id}`} style={{color: 'inherit', textDecoration: "none"}}>
          <BundleListNameCell name={row.original.bundleListName.name} createdBy={row.original.bundleListName.createdBy} id={row.original.bundleListName.id}/>
        </Link></div>)}
    },
    { id: 'lastUpdate',accessor: 'lastUpdate', header: 'Last Update',cell: ({ row }) =>{
        return  row.original.lastUpdate}
    },
    { id: 'bundles',accessor: 'bundles', header: 'Bundles',cell: ({ row  }) => {
        return `${row.original.bundles.length} Bundles`}
    },
    { id: 'actions', display: 'actions',size: 65,cell: ({ row  }) => {
        return  <ActionsCell row={row.original} actionsNames={["Duplicate Bundle List","Edit Bundle List"]} callbackActions={ [() => {}, () => {}]}/>}
    }
  ];


  return (
    <Grid container>
      <Grid item xs={10} style={{ paddingTop: "70px", width: "90%" }}>
        <Grid item>
          {"Bundle Lists"}
        </Grid>
        <Paper>
          <BeforeTable title={"Bundle Lists"} placeholder={"Bundle Lists"} buttonOnClick={() => console.log("button add bundle list")} />
          <Divider/>
          <Table
            id="bundle-list-table"
            data={data !== null ? data : []}
            columns={columns}
            getRowProps={(row) => {
              const bundleList = row.original;
              return {
                style: { cursor: 'pointer' },
                onClick: () => handleRowClick(bundleList)
              }
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );

}


