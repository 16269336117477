import { createContext, useState } from "react";
import { AlertColor } from "@mui/material/Alert/Alert";

export const SnackbarNotificationsContext = createContext({
  notification: {
    open: false,
    type: "success" as AlertColor,
    message: "",
  },
  showNotification: (type, message) => {
  },
  closeNotification: () => {},
})

export const SnackbarNotificationsProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    open: false,
    type: "success" as AlertColor,
    message: "",
  })

  const showNotification = (type, message) => {
    setNotification({
      open: true,
      type,
      message,
    })
  }

  const closeNotification = () => {
    setNotification({
      ...notification,
      open: false,
    })
  }

  return (
    <SnackbarNotificationsContext.Provider
      value={{ notification, showNotification, closeNotification }}
    >
      {children}
    </SnackbarNotificationsContext.Provider>
  )
}
