// UserControlTargeting.tsx
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  Typography,
  Stack,
  CardHeader,
  CardContent,
  Card
} from "@mui/material";
import { Controller } from 'react-hook-form';
import Divider from "@mui/material/Divider";

// Enums used in UserControlTargeting
export const ExistsIFAs =  {
  all:"Both",
  yes:"Yes",
  no:"No",
}

export const DNTActivated = {
  yes: "On",
  no: "Off",
  all: "Both",
}

export const DeviceMuted = {
  yes: "Muted Only",
  no: "Not Muted Only",
  all: "All Sound Types"
}

// Props that UserControlTargeting expects
interface UserControlTargetingProps {
  control: any; // Replace with the correct type from react-hook-form
}

export default function UserControlTargeting( props:UserControlTargetingProps){
  const {control} = props
  return (
    <Card variant={"outlined"} style={{marginTop:12}}>
      <CardHeader titleTypographyProps={{ variant: "caption" }} title={"User Control Targeting"} />
      <CardContent>
      <Controller
        name="deal.conditions-json.hasIFA.value"
        control={control}
        render={({ field }) => {
          return (
          <FormControl fullWidth margin="normal">
            <InputLabel id="hasIFA-tag-select-label">IFA Exists</InputLabel>
            <Select
              {...field}
              value={field.value ?? ''}
              labelId="hasIFA-tag-select-label"
              label="IFA Exists">
              {Object.entries(ExistsIFAs).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {ExistsIFAs[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}}
      />

      <Controller
        name="deal.conditions-json.dnt.value"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="dnt-tag-select-label">DNT</InputLabel>
            <Select {...field} value={field.value ?? ''} labelId="dnt-tag-select-label" label="DNT">
              {Object.entries(DNTActivated).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {DNTActivated[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />


      {/* Device Muted Select */}
      <Controller
        name="deal.conditions-json.muted.value"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="deviceMuted-tag-select-label">Device Muted</InputLabel>
            <Select
              {...field}
              value={field.value ?? ''}
              labelId="deviceMuted-tag-select-label"
              label="Device Muted">
              {Object.entries(DeviceMuted).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {DeviceMuted[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      </CardContent>
    </Card>
  );
}
